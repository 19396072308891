.container_chatbot {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

@media only screen and (min-width: 768px) {
  .container_chatbot {
    padding: 0 5px;
    width: 100%;
    height: 60%;
  }
}

@media only screen and (min-width: 1024px) {
  .container_chatbot {
    padding: 0 5px;
    width: 100%;
    height: 80%;
  }
}

@media only screen and (min-width: 1280px) {
  .container_chatbot {
    padding: 0 5px;
    width: 100%;
    height: 75%;
  }
}


