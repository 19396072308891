.container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.input_search {
  width: 20%;
  /* height: 70%; */
}

.btn_home {
  position: absolute;
  top: 5%;
  right: 3%;
  width: 6%;
  z-index: 1;
  cursor: pointer;
}
.btn_back {
  position: absolute;
  top: 5%;
  right: 3%;
  width: 4%;
  z-index: 1;
  cursor: pointer;
}

.btn_Create {
  /* background: linear-gradient(
    93.04deg,
    #a2d8de -42.16%,
    #65bfcc 21.8%,
    #73d3d3 92.64%,
    #65bfcc 146.76%
  ); */
  background-color: #6fc3cf;
  border-radius: 24px;
  /* color: black; */
  display: flex;
  justify-items: center;
  align-items: center;
  height: 70%;
}

.container_ebook {
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
}

.header_ebook {
  width: 90%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.header_contentEbook {
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.list_ebook {
  width: 90%;
  height: 70%;
  overflow-x: hidden;
  text-align: center;
}

.content_tabsEbook {
  width: 90%;
  height: 80%;
}

.ebook_image {
  width: 100%;
  height: 100%;
  padding: 2vh;
}

.gutter_row {
}

.ebook_image:hover {
  transform: rotate(-5.39deg);
  transition: all;
  filter: drop-shadow(10px 5px 10px rgb(12, 151, 128));
  border-radius: 20px;
}

.container_createEbook {
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
  -webkit-backdrop-filter: blur(42px);
  backdrop-filter: blur(42px);
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.form_createEbook {
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ui_book {
  background: url("../../assets/background/backgound_book.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.content_book {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}

.content_ebook {
  width: 80%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.btn_switch {
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ebook_edit {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
  -webkit-backdrop-filter: blur(42px);
  backdrop-filter: blur(42px);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Switch {
  width: 50%;
}

.content_step {
  box-sizing: border-box;
  width: 100%;
  height: 58px;
  background: #65bfcc;
  box-shadow: 0px 8px 10px rgba(101, 191, 204, 0.42),
    inset 0px -3px 14px rgba(0, 114, 118, 0.3);
  border-radius: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.content_formCreate {
  width: 100%;
}

.btn_Next {
  color: black;
  border-color: black;
}

.btn_Next :hover {
  color: white;
  border-color: white;
}

.content_formCreate p {
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 49px;
  display: flex;
  align-items: center;
  background: linear-gradient(139.36deg, #6b7aff -0.03%, #1430c6 120.49%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.titleBook {
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #65bfcc;
}

.logo_ebook {
  z-index: 1;
  width: 14%;
  position: absolute;
  left: 0;
}

.logo_ebook_home {
  z-index: 1;
  width: 12%;
}

/* @media screen and (min-width: 1280px) {
  .content_formCreate p {
    font-size: 29px;
    line-height: 50px;  
  }

  .ui_book {
    width: 70%;
  }
}

@media screen and (min-width: 1440px) {
  .content_formCreate p {
    font-size: 32px;
  }
}

@media screen and (min-width: 1920px) {
} */
