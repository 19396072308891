.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  width: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
}

.form-container {
  width: 40%;
  height: 90%;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 20px;
  border: 3px solid #ffffff;
}

.separator {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 80%;
  margin: 0 auto;
  padding-bottom: 20px;
}

.separator .line {
  flex-grow: 1;
  height: 1px;
  background-color: #386386;
}

.separator .text {
  margin: 0 10px;
  color: #999;
}

/* .separator::before,
.separator::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 10px;
  height: 1px;
  background-color: #ccc;
} */

.separator::before {
  left: 0;
}
.separator::after {
  right: 0;
}

@media screen and (min-width: 1024px) {
  .container {
    max-width: unset !important;
  }
}

/* @media screen and (min-width: 1280px) {
  .form-container {
    height: 65%;
  }
}

@media screen and (min-width: 1440px) {
  .form-container {
    height: 70%;
  }
}

@media screen and (min-width: 1920px) {
  .form-container {
    height: 55%;
  }
} */
