.custom-tooltip .ant-tooltip-inner {
  color: #2d45d3;
  border: 1px solid #2d45d3;
  font-weight: bold;
  width: 160px;
  text-align: center;
  padding: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
}

.custom-tooltip {
  z-index: 0;
}

.custom-tooltip .ant-tooltip-arrow {
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent #2d45d3 transparent;
}

.content_progressBar {
  width: 100%;
  /* height: 20%; */
  display: flex;
  align-items: center;
}

.row_progressbar {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 30%;
}

.IconProgess {
  background-color: linear-gradient(to bottom right, #00eee0, #0057ff);
  border-radius: 100%;
}

.custom_col_processBar {
  flex: 0 0 65%;
  max-width: 60%;
  height: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-table-wrapper .ant-table-thead > tr > th {
  background: #73d3d3;
  color: white;
  text-align: center;
  font-size: 8px;
}

.ant-table-tbody {
  text-align: center;
}

.ant-tabs-nav-wrap {
  justify-content: center;
  font-size: 30px;
}

.container_processBard {
  height: 15vh;
  position: relative;
}

.RSPBprogressBar {
  width: 100%;
  height: 30%;
}

.RSPBstep img {
  max-width: fit-content;
}

@media screen and (min-width: 1280px) {
  .custom_col_processBar {
    height: 10vh;
  }
  .ant-table-wrapper .ant-table-thead > tr > th {
    font-size: 12px;
  }
}

.inforUser_name {
  background-color: red;
}

@media screen and (min-width: 1280px) {
  .custom-tooltip .ant-tooltip-inner {
    font-size: 16px;
  }
}
