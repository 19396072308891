.select_User {
  width: 80%;
}

.title_ApprovalRequest {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 26px;
  color: #2a4365;
}

.btn_addPage {
  border-radius: 8px;
  background-image: linear-gradient(
    154deg,
    #6b7aff 0%,
    #1430c6 100%
  ) !important;
  color: white;
  /* display: flex !important;
    left: 40% !important; */
}

@media screen and (min-width: 1280px) {
  .title_ApprovalRequest {
    font-size: 15px;
  }
}

@media screen and (min-width: 1440px) {
  .formItem {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1920px) {
  .formItem {
    margin-bottom: 24px;
  }
}
