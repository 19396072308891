@import url("https://fonts.googleapis.com/css2?family=Itim&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.react-pdf__Page {
  width: 500px;
  height: 680px;
}

.react-pdf__Document {
  display: block;
}

.ant-modal-confirm-btns .ant-btn {
  background-color: #1677ff;
}

.ant-modal-header {
  background-color: #f6f5f2 !important;
}

.prevent-select {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
/* 
.scrollable-container {
  position: relative;
  overflow: hidden;
}

.scrollable-container::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 16px;
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.scrollable-container:hover::after {
  opacity: 1;
}

.content {
  overflow-y: scroll;
  height: 40%;
  padding-right: 16px;
  overflow-x: hidden;
}

.content::-webkit-scrollbar {
  width: 8px;
}

.content::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
}

.content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.content::-webkit-scrollbar-track {
  background-color: transparent;
} */

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 30px;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.primary-button {
  background-color: #4e62eb !important;
}

/* @layer utilities {
  @variants responsive {
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    .no-scrollbar {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }
} */
