.inquiryContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
}
.title {
  color: #2a4365;
  font-family: "Roboto";
  font-weight: 900;
  padding-bottom: 29px;
}
.btnList {
  padding: 18px 33px;
  background: #465ae6;
  font-size: 16px;
  color: white;
  border-radius: 10px;
  border: 0;
}
.btnSubmit {
  padding: 19px 43px;
}

.inquiryPage {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.formInquiry {
  width: 50% !important;
  margin-left: -156px !important;
}

.btn_Inquiry {
  width: 30%;
  left: 100px;
  border: none;
  border-radius: 20px;
  color: white;
  background-color: #fe998d !important;
  padding: 0 5px;
}
