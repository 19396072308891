.container_Infor {
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: space-evenly;
}

.container_listCampus {
  width: 100%;
  height: 70vh;
  text-align: center;
}
.title_listCampus {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 26px;
  color: #2a4365;
  width: 40%;
  text-align: start;
}

.filter_Username,
.filter_Date {
  width: 40%;
  height: 10%;
  display: grid;
  grid-template-rows: auto;
  gap: 10px;
}
.filter_Date {
  height: 20%;
}

.list_tableCampus {
  width: 85%;
  overflow: hidden;
}

.list_user {
  width: 20%;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  background: #edf2f7;
  border-radius: 15px;
  gap: 10px;
  padding: 10px;
}
.checkBox {
  width: 100%;
  grid-template-columns: auto auto auto;
  display: grid;
}
.checkBox .title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 26px;
}

.user_infor {
  width: 70%;
}
.user_infor_title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 26px;
  color: #2a4365;
  text-align: center;
}

.user_infor img {
  width: 100%;
  height: 100%;
  border: 2px solid purple;
  border-radius: 20px;
}
.avatar_user {
  display: grid;
  grid-template-rows: auto auto;
  justify-items: center;
  align-items: center;
  gap: 15px;
}

.content_approval {
  width: 100%;
  height: 100%;
  display: grid;
  grid-auto-rows: auto;
}

.form_approval {
  padding: 20px 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn_chooseFile {
  background: #a2d8de;
  border-radius: 5px;
}

.checkBox_filter {
  display: flex;
  justify-content: start;
  align-items: center;
}
.btn_listCampus {
  background: #a2d8de;
  border-radius: 8px;
}

.form_approval_request_admin {
  overflow: scroll;
  padding: 22px 0;
}

@media screen and (min-width: 1280px) {
  .user_infor img {
    width: 70%;
    height: 100%;
  }
  .list_tableCampus {
    width: 100%;
  }
}
@media screen and (min-width: 1440px) {
  .user_infor {
    overflow-y: auto;
    overflow-x: hidden;
  }
}
@media screen and (min-width: 1920px) {
}
