.container_mainChat {
  max-height: 80%;
  height: 40vh;
  max-width: 75%;
  width: 65%;
  overflow-y: auto;
  padding: 10px 20px;
}

.contain_iconChat {
  display: flex;
  float: right;
}

/*  */
.lineChatBot {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.lineChatBot .fm_chat {
  color: #fff;
  max-width: 60%;
  padding: 10px 10px;
  border-radius: 24px 24px 24px 0px;
  background: linear-gradient(
    93.04deg,
    #a2d8de -42.16%,
    #65bfcc 21.8%,
    #73d3d3 92.64%,
    #65bfcc 146.76%
  );
  color: #fff;
  white-space: pre-wrap;
}

.lineChatClient {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: row-reverse;
}

.lineChatClient .fm_chat {
  max-width: 60%;
  padding: 5px 5px;
  border-radius: 15px 15px 0px 15px;
  background: white;
  color: black;
  border: 1px solid #65bfcc;
}

.avt {
  padding: 5px 5px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 16px;
}

.avt img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.button_topic {
  text-align: left;
  margin-top: 10px;
  font-family: Mulish;
  font-style: Medium;
  font-size: 14px;
  line-height: 15px;
  padding: 7px 16px 7px 16px;
  border-radius: 16px;
  background: #fcfcfc;
  border: 1px solid #c6c6c6;
  margin-right: 10px;
}

.button_topic:hover {
  background: #f2f2f2;
  border: 1px solid #919191;
}

.button_topic:active {
  background: #37cdbc;
  border: 1px solid #37cdbc;
}

.quiz {
  background: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Mulish;
  font-style: Medium;
  font-size: 14px;
  line-height: 18px;
  padding: 7px, 16px, 7px, 16px;
  width: 131px;
  height: 32px;
  border-radius: 16px;
  border: 1px solid #37cdbc;
  box-shadow: #37cdbc 2px 2px 0 0;
}

.quiz:hover {
  background: #daf6f3;
}

.quiz:active {
  box-shadow: #37cdbc 0px 0px 0 0;
  transform: translate(2px, 2px);
}

.loader {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #f2f2f2;
  /* slightly darker shade of white */
  box-shadow: 32px 0 #f2f2f2, -32px 0 #f2f2f2;
  animation: flashLight 0.5s ease-out infinite alternate;
  margin: auto;
  margin-left: 45px;
}

@keyframes flashLight {
  0% {
    background-color: #282c2f;
    box-shadow: 32px 0 #282c2f, -32px 0 #f2f2f2;
    /* slightly darker shade of white */
  }

  50% {
    background-color: #f2f2f2;
    /* slightly darker shade of white */
    box-shadow: 32px 0 #282c2f, -32px 0 #282c2f;
  }

  100% {
    background-color: #282c2f;
    box-shadow: 32px 0 #f2f2f2, -32px 0 #282c2f;
    /* slightly darker shade of white */
  }
}

.referenceLink {
  display: flex;
  flex-direction: column;
  padding: 8px 0px;
  width: 70%;
  margin: 10px 0 0 65px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  border: 1px solid #cad2c5;
  border-radius: 0px 24px 24px 24px;
}

.referenceLink path {
  fill: #000;
}

.referenceLink .smallLink {
  cursor: pointer;
  transition: 0.2s;
  background: #e5e9e3;
}

.referenceLink .smallLink:hover {
  background: #cad2c5;
}

.referenceLink span {
  height: 16px;
}

.titleInput {
  display: flex;
  justify-content: space-between;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin-top: 3px;
  padding-right: 3px;
  /* identical to box height */
  /* Neutral/White */
  opacity: 0.9;
}

.titleInput .d1 {
  padding-top: 5px;
  margin-left: 20px;
}

.titleInput .d2 {
  cursor: pointer;
  display: flex;
  justify-content: center;
  justify-items: center;
  gap: 12px;
  margin-right: 10px;
  padding: 5px 10px;
  border-radius: 15px;
  transition: 0.2s;
}

.titleInput .d2:hover {
  background: #e2e2e2;
}

.titleInput .d2 > span {
  display: flex;
  align-items: center;
}

.boxLink {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding-left: 20px;
  margin: 10px 0 2px 0;
}

.smallLink {
  max-width: 80%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px 20px 7px 16px;
  gap: 10px;
  flex: none;
  height: 32px;
  order: 1;
  flex-grow: 0;
  border-radius: 16px;
  cursor: pointer;
}

.smallLink {
  transition: 0.2s;
  background: #f8f8f8;
}

.smallLink:hover {
  background: #e2e2e2;
}

.smallLink img {
  width: 20px;
  height: 20px;
  border-radius: 20px;
}

.smallLink a {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  background-color: transparent;
  text-decoration: none;
}

.largeLink {
  margin-top: 10px;
  width: 100%;
  padding-bottom: 5px;
}

.largeLink .div_border {
  padding-top: 10px;
  border-bottom: 1px solid #eaede8;
}

.largeLink .h1_link {
  display: flex;
  justify-content: space-between;
  padding-right: 5px;
  justify-items: center;
}

.largeLink .h1_link .accessLink {
  cursor: pointer;
  transition: 0.2s;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.accessLink path {
  fill: #354f52;
}

.largeLink .h1_link .accessLink:hover {
  background: #cad2c5;
}

.largeLink .h1_link div {
  display: flex;
  gap: 8px;
}

.largeLink .h1_link div span {
  margin-top: 4px;
}

.largeLink .h1_link img {
  width: 24px;
  height: 24px;
  border-radius: 24px;
}

.overfow_text {
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.d2 path {
  fill: #354f52;
}

.quiz_choices {
  position: relative;
  text-align: left;
  margin-top: 8px;
  border-radius: 16px;
  width: 100%;
  height: 100%;
  padding: 14px 16px;
  background: #ffffff;
  border: 1px solid #bbbced;
  box-shadow: 2px 2px 0px 0px #bbbced;
}

.quiz_choices:hover {
  border: 1px solid #6c6fd7;
  box-shadow: 2px 2px 0px 0px #6c6fd7;
}

.quiz_choices:active {
  background: #f7f7fd;
  box-shadow: 0px 0px 0px 0px;
  border: 1px solid #bbbced;
}

.quiz_choices:disabled {
  background: #ffffff;
  color: #919191;
  border: 1px solid #e2e2e2;
  box-shadow: 0px 0px 0px 0px;
}

.quiz_choices.true {
  border: 1px solid #37cdbc;
  box-shadow: 0px 0px 0px 0px;
}

.quiz_choices.true::before {
  content: url(../../../assets/icon_svg/svg/v.svg);
  position: absolute;
  top: 50%;
  transform: translateY(-39%);
}

.quiz_choices.false {
  border: 1px solid #e02336;
  box-shadow: 0px 0px 0px 0px;
}

.quiz_choices.false::before {
  content: url(../../../assets/icon_svg/svg/x.svg);
  position: absolute;
  top: 50%;
  transform: translateY(-39%);
}

@media only screen and (max-width: 900px) {
  .lineChatBot .fm_chat {
    max-width: 80%;
  }

  .lineChatClient .fm_chat {
    max-width: 80%;
  }

  .avt {
    width: 32px;
    height: 32px;
  }

  .avt img {
    width: 32px;
    height: 32px;
  }
}

@media only screen and (max-width: 1280px) {
  .titleInput .d2 > span {
    font-size: 12px;
  }

  .titleInput .d1 {
    font-size: 12px;
  }
}
