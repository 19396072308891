.flipbook_container {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.flipbook_content {
  width: 100%;
  height: 100%;
}

.flipbook_content_menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  top: 0;
}

.cover_page,
.page {
  width: 100%;
  height: 100%;
}

.page img {
  width: 100%;
  height: 100%;
}

.cover_page img {
  width: 100%;
  height: 100%;
}

.demoPage {
  background: red;
}

.ebook_menu {
  position: absolute;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.ebook {
  color: white;
  font-size: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
}

.iconBack {
  width: 30px;
  color: white;
  display: flex;
  align-items: center;
}

.Back {
  display: flex;
  background-color: #5366ef;
  border-radius: 15px;
  padding: 10px 10px;
  align-items: center;
  width: 100px;
  cursor: pointer;
}

.text {
  color: white;
}

.flipbook_content__edit {
  /* height: 70px; */
}

.Edit {
  display: flex;
  background-color: #68c3cd;
  border-radius: 15px;
  padding: 10px 10px;
  align-items: center;
  width: 100px;
  cursor: pointer;
}

.iconEdit {
  width: 30px;
  color: white;
  display: flex;
  align-items: center;
  margin-left: 10px;
  padding: 5px;
}

.flipbook_content__lession {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #68c3cd;
  width: 371px;
  border-radius: 25px;
}

.iconPrev {
  width: 50px;
  color: white;
  display: flex;
  align-items: center;
  margin-left: 10px;
  padding: 5px;
}

.iconNext {
  width: 50px;
  color: white;
  display: flex;
  align-items: center;
  margin-left: 10px;
  padding: 5px;
  cursor: pointer;
}

.progress {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.numberpage {
  color: white;
  font-size: 30px;
}
