.container {
  background-image: url("../../assets//background/backgound_draf.png");
  background-repeat: no-repeat;
  background-size: 90% 100%;
  background-position: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.textareaWrapper {
  margin-top: 35px;
  position: relative;
  width: 80%;
  overflow-x: hidden;
}

.textareaWrapper textarea {
  border: none;
  padding: 5px;
  font-size: 16px;
  word-wrap: break-word;
  resize: vertical;
  overflow-wrap: break-word;
  background: linear-gradient(
      to bottom,
      transparent 2px,
      #b1ada6 2px,
      #ccc 4px,
      transparent 4px
    ),
    #f3e9d3;
  background-size: 100% 20px;
  background-clip: content-box, border-box;
  width: 100%;
  height: 350px !important;
  outline: none;
  resize: none;
}

.boxSuggestion {
  border: none;
  font-size: 14px;
  word-wrap: break-word;
  resize: vertical;
  overflow-wrap: break-word;
  background-size: 100% 20px;
  background-clip: content-box, border-box;
  width: 100%;
  height: 350px !important;
  outline: none;
  resize: none;
}
textarea::-webkit-scrollbar {
  width: 8px;
}

textarea::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

textarea::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.content_icon {
  width: 60%;
  display: flex;
  justify-content: space-between;
}

.textError_red {
  font-size: 15px;
  background: #ffedd5;
  color: black;
}

.textError_green {
  font-size: 15px;
  background: #b7eb8f;
  color: black;
}

.textError_blue {
  background: #91caff;
  color: black;
}
@media screen and (min-width: 1024px) {
  .textareaWrapper {
    width: 80%;
  }
}
@media screen and (min-width: 1280px) {
  .container {
    height: 95%;
  }

  .textareaWrapper {
    height: 70%;
  }
}

@media screen and (min-width: 1440px) {
  .boxSuggestion .textError_red,
  .textError_green {
    font-size: 16px;
  }
}
