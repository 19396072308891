.carousel .thumb img {
  width: 100%;
}

li.thumb.selected,
li.thumb {
  width: 70% !important;
}

@media only screen and (min-width: 768px) {}

@media only screen and (min-width: 1028px) {

  li.thumb.selected,
  li.thumb {
    width: 50% !important;
  }
}

@media only screen and (min-width: 1440px) {

  li.thumb.selected,
  li.thumb {
    width: 50% !important;
  }
}

button.control-arrow.control-next,
button.control-arrow.control-prev {
  display: none;
}

.carousel .slide img {
  width: 20%;
}

.btn-check {
  min-width: 100px;
  min-height: 48px;
}

.custom-switch {
  /* Add your custom styles here */
  /* Example styles */
  background-color: #e8e8e8;
  border-radius: 16px;
}

.custom-switch .ant-switch-inner {
  /* Modify the inner part of the switch */
  /* Example styles */
  border-radius: 16px;
}

/* Button 2D 3D css */
:where(.css-dev-only-do-not-override-12upa3x).ant-radio-button-wrapper:hover {
  position: relative;
  color: #2f3035;
  background: #6b7aff;
}

.ant-radio-group-solid :where(.css-dev-only-do-not-override-12upa3x).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.ant-radio-group-solid :where(.css-dev-only-do-not-override-12upa3x).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
  background: #6b7aff;
  border-radius: 50px;
  margin: -29px;
}

:where(.css-dev-only-do-not-override-12upa3x).ant-radio-button-wrapper:hover {
  position: relative;
  color: #ccc;
  background: #dbdce3;
}

:where(.css-dev-only-do-not-override-12upa3x).ant-radio-button-wrapper:first-child {
  border-radius: 50px;
  margin-left: -20px;
}

:where(.css-dev-only-do-not-override-12upa3x).ant-radio-button-wrapper:last-child {
  border-radius: 50px;
}

:where(.css-dev-only-do-not-override-12upa3x).ant-radio-button-wrapper {
  position: relative;
  display: inline-block;
  font-weight: 570;
  height: 40px;
  width: 86px;
  margin: 0;
  padding-inline: 15px;
  padding-block: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 19px;
  line-height: 30px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-block-start-width: 1.02px;
  border-inline-start-width: 0;
  border-inline-end-width: 1px;
  cursor: pointer;
  transition: color 0.2s, background 0.2s, border-color 0.2s, box-shadow 0.2s;
}

:where(.css-dev-only-do-not-override-12upa3x).ant-radio-button-wrapper:not( :first-child)::before {
  width: 0;
  position: absolute;
  inset-block-start: -1px;
  inset-inline-start: -1px;
  display: block;
  box-sizing: content-box;
  height: 100%;
  padding-block: 1px;
  padding-inline: 0;
  background-color: #d9d9d9;
  transition: background-color 0.3s;
  content: "";
}

.custom-switch .ant-switch-handle {
  /* Modify the handle of the switch */
  /* Example styles */
  background-color: #fff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

.carousel .slide {
  min-width: 100%;
  margin: 0;
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.firefly {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 0.4vw;
  height: 0.4vw;
  margin: -0.2vw 0 0 9.8vw;
  animation: ease 200s alternate infinite;
  pointer-events: none;
}

.firefly::before,
.firefly::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transform-origin: -10vw;
}

.firefly::before {
  background: black;
  opacity: 0.4;
  animation: drift ease alternate infinite;
}

.firefly::after {
  background: white;
  opacity: 0;
  box-shadow: 0 0 0vw 0vw yellow;
  animation: drift ease alternate infinite, flash ease infinite;
}

.firefly:nth-child(1) {
  animation-name: move1;
}

.firefly:nth-child(1)::before {
  animation-duration: 18s;
}

.firefly:nth-child(1)::after {
  animation-duration: 18s, 7590ms;
  animation-delay: 0ms, 7619ms;
}

@keyframes move1 {
  0% {
    transform: translateX(-2vw) translateY(-24vh) scale(0.56);
  }

  5.88235% {
    transform: translateX(3vw) translateY(-16vh) scale(0.92);
  }

  11.76471% {
    transform: translateX(-39vw) translateY(11vh) scale(0.67);
  }

  17.64706% {
    transform: translateX(-21vw) translateY(16vh) scale(0.55);
  }

  23.52941% {
    transform: translateX(15vw) translateY(-22vh) scale(0.37);
  }

  29.41176% {
    transform: translateX(-7vw) translateY(11vh) scale(0.33);
  }

  35.29412% {
    transform: translateX(-15vw) translateY(-36vh) scale(0.6);
  }

  41.17647% {
    transform: translateX(-1vw) translateY(-26vh) scale(0.99);
  }

  47.05882% {
    transform: translateX(15vw) translateY(13vh) scale(0.47);
  }

  52.94118% {
    transform: translateX(25vw) translateY(-27vh) scale(0.62);
  }

  58.82353% {
    transform: translateX(6vw) translateY(23vh) scale(0.76);
  }

  64.70588% {
    transform: translateX(-46vw) translateY(45vh) scale(0.41);
  }

  70.58824% {
    transform: translateX(-10vw) translateY(2vh) scale(0.41);
  }

  76.47059% {
    transform: translateX(-38vw) translateY(-23vh) scale(0.74);
  }

  82.35294% {
    transform: translateX(-32vw) translateY(-33vh) scale(0.56);
  }

  88.23529% {
    transform: translateX(-23vw) translateY(40vh) scale(0.76);
  }

  94.11765% {
    transform: translateX(11vw) translateY(-2vh) scale(0.76);
  }

  100% {
    transform: translateX(20vw) translateY(-13vh) scale(0.91);
  }
}

.firefly:nth-child(2) {
  animation-name: move2;
}

.firefly:nth-child(2)::before {
  animation-duration: 9s;
}

.firefly:nth-child(2)::after {
  animation-duration: 9s, 9559ms;
  animation-delay: 0ms, 4210ms;
}

@keyframes move2 {
  0% {
    transform: translateX(39vw) translateY(10vh) scale(0.4);
  }

  3.84615% {
    transform: translateX(8vw) translateY(3vh) scale(0.31);
  }

  7.69231% {
    transform: translateX(27vw) translateY(36vh) scale(0.3);
  }

  11.53846% {
    transform: translateX(42vw) translateY(-17vh) scale(0.75);
  }

  15.38462% {
    transform: translateX(12vw) translateY(41vh) scale(0.45);
  }

  19.23077% {
    transform: translateX(42vw) translateY(34vh) scale(0.29);
  }

  23.07692% {
    transform: translateX(-31vw) translateY(20vh) scale(0.6);
  }

  26.92308% {
    transform: translateX(-30vw) translateY(37vh) scale(0.27);
  }

  30.76923% {
    transform: translateX(10vw) translateY(10vh) scale(0.75);
  }

  34.61538% {
    transform: translateX(24vw) translateY(21vh) scale(0.73);
  }

  38.46154% {
    transform: translateX(26vw) translateY(-28vh) scale(0.97);
  }

  42.30769% {
    transform: translateX(-26vw) translateY(-16vh) scale(0.99);
  }

  46.15385% {
    transform: translateX(18vw) translateY(-27vh) scale(0.84);
  }

  50% {
    transform: translateX(-36vw) translateY(42vh) scale(0.7);
  }

  53.84615% {
    transform: translateX(7vw) translateY(-28vh) scale(0.85);
  }

  57.69231% {
    transform: translateX(-20vw) translateY(7vh) scale(0.58);
  }

  61.53846% {
    transform: translateX(2vw) translateY(29vh) scale(0.38);
  }

  65.38462% {
    transform: translateX(-3vw) translateY(-7vh) scale(0.45);
  }

  69.23077% {
    transform: translateX(-43vw) translateY(4vh) scale(0.98);
  }

  73.07692% {
    transform: translateX(1vw) translateY(16vh) scale(0.99);
  }

  76.92308% {
    transform: translateX(23vw) translateY(14vh) scale(0.73);
  }

  80.76923% {
    transform: translateX(-33vw) translateY(-30vh) scale(0.87);
  }

  84.61538% {
    transform: translateX(-24vw) translateY(43vh) scale(0.35);
  }

  88.46154% {
    transform: translateX(-24vw) translateY(-3vh) scale(0.39);
  }

  92.30769% {
    transform: translateX(7vw) translateY(-28vh) scale(0.48);
  }

  96.15385% {
    transform: translateX(7vw) translateY(33vh) scale(0.77);
  }

  100% {
    transform: translateX(-6vw) translateY(-9vh) scale(0.65);
  }
}

.firefly:nth-child(3) {
  animation-name: move3;
}

.firefly:nth-child(3)::before {
  animation-duration: 13s;
}

.firefly:nth-child(3)::after {
  animation-duration: 13s, 10611ms;
  animation-delay: 0ms, 2939ms;
}

@keyframes move3 {
  0% {
    transform: translateX(-40vw) translateY(25vh) scale(0.39);
  }

  4% {
    transform: translateX(-20vw) translateY(7vh) scale(0.48);
  }

  8% {
    transform: translateX(-35vw) translateY(-7vh) scale(0.86);
  }

  12% {
    transform: translateX(-21vw) translateY(41vh) scale(0.49);
  }

  16% {
    transform: translateX(36vw) translateY(-37vh) scale(0.79);
  }

  20% {
    transform: translateX(36vw) translateY(-17vh) scale(0.91);
  }

  24% {
    transform: translateX(44vw) translateY(-5vh) scale(0.83);
  }

  28% {
    transform: translateX(11vw) translateY(32vh) scale(1);
  }

  32% {
    transform: translateX(-45vw) translateY(47vh) scale(0.34);
  }

  36% {
    transform: translateX(8vw) translateY(-47vh) scale(0.79);
  }

  40% {
    transform: translateX(-34vw) translateY(-21vh) scale(0.98);
  }

  44% {
    transform: translateX(-2vw) translateY(44vh) scale(0.74);
  }

  48% {
    transform: translateX(-38vw) translateY(-4vh) scale(0.45);
  }

  52% {
    transform: translateX(17vw) translateY(-12vh) scale(0.9);
  }

  56% {
    transform: translateX(6vw) translateY(36vh) scale(0.62);
  }

  60% {
    transform: translateX(16vw) translateY(-13vh) scale(0.53);
  }

  64% {
    transform: translateX(-1vw) translateY(-14vh) scale(0.31);
  }

  68% {
    transform: translateX(-10vw) translateY(-24vh) scale(0.97);
  }

  72% {
    transform: translateX(-42vw) translateY(-22vh) scale(0.52);
  }

  76% {
    transform: translateX(-27vw) translateY(18vh) scale(0.5);
  }

  80% {
    transform: translateX(5vw) translateY(47vh) scale(0.87);
  }

  84% {
    transform: translateX(-32vw) translateY(2vh) scale(0.54);
  }

  88% {
    transform: translateX(12vw) translateY(-20vh) scale(0.57);
  }

  92% {
    transform: translateX(48vw) translateY(-16vh) scale(0.85);
  }

  96% {
    transform: translateX(9vw) translateY(-23vh) scale(0.96);
  }

  100% {
    transform: translateX(-2vw) translateY(-47vh) scale(0.85);
  }
}

.firefly:nth-child(4) {
  animation-name: move4;
}

.firefly:nth-child(4)::before {
  animation-duration: 12s;
}

.firefly:nth-child(4)::after {
  animation-duration: 12s, 5311ms;
  animation-delay: 0ms, 6793ms;
}

@keyframes move4 {
  0% {
    transform: translateX(6vw) translateY(-5vh) scale(0.73);
  }

  4% {
    transform: translateX(-46vw) translateY(24vh) scale(0.35);
  }

  8% {
    transform: translateX(-26vw) translateY(16vh) scale(0.42);
  }

  12% {
    transform: translateX(-13vw) translateY(45vh) scale(0.68);
  }

  16% {
    transform: translateX(-12vw) translateY(-41vh) scale(0.46);
  }

  20% {
    transform: translateX(12vw) translateY(43vh) scale(0.3);
  }

  24% {
    transform: translateX(25vw) translateY(14vh) scale(0.8);
  }

  28% {
    transform: translateX(-49vw) translateY(50vh) scale(0.37);
  }

  32% {
    transform: translateX(19vw) translateY(41vh) scale(0.95);
  }

  36% {
    transform: translateX(-41vw) translateY(-38vh) scale(0.79);
  }

  40% {
    transform: translateX(34vw) translateY(32vh) scale(0.3);
  }

  44% {
    transform: translateX(23vw) translateY(2vh) scale(0.6);
  }

  48% {
    transform: translateX(-48vw) translateY(-19vh) scale(0.89);
  }

  52% {
    transform: translateX(-17vw) translateY(15vh) scale(0.27);
  }

  56% {
    transform: translateX(-29vw) translateY(46vh) scale(0.61);
  }

  60% {
    transform: translateX(24vw) translateY(-39vh) scale(0.57);
  }

  64% {
    transform: translateX(43vw) translateY(28vh) scale(0.5);
  }

  68% {
    transform: translateX(-33vw) translateY(-29vh) scale(0.79);
  }

  72% {
    transform: translateX(-16vw) translateY(-11vh) scale(0.74);
  }

  76% {
    transform: translateX(14vw) translateY(17vh) scale(0.64);
  }

  80% {
    transform: translateX(-33vw) translateY(13vh) scale(0.43);
  }

  84% {
    transform: translateX(46vw) translateY(-47vh) scale(0.61);
  }

  88% {
    transform: translateX(-47vw) translateY(17vh) scale(0.53);
  }

  92% {
    transform: translateX(-20vw) translateY(-22vh) scale(0.5);
  }

  96% {
    transform: translateX(46vw) translateY(22vh) scale(0.96);
  }

  100% {
    transform: translateX(44vw) translateY(-39vh) scale(0.92);
  }
}

.firefly:nth-child(5) {
  animation-name: move5;
}

.firefly:nth-child(5)::before {
  animation-duration: 15s;
}

.firefly:nth-child(5)::after {
  animation-duration: 15s, 8996ms;
  animation-delay: 0ms, 5302ms;
}

@keyframes move5 {
  0% {
    transform: translateX(-31vw) translateY(45vh) scale(0.79);
  }

  3.84615% {
    transform: translateX(-48vw) translateY(-11vh) scale(0.28);
  }

  7.69231% {
    transform: translateX(20vw) translateY(-16vh) scale(0.42);
  }

  11.53846% {
    transform: translateX(37vw) translateY(47vh) scale(0.76);
  }

  15.38462% {
    transform: translateX(20vw) translateY(34vh) scale(0.93);
  }

  19.23077% {
    transform: translateX(-31vw) translateY(19vh) scale(0.64);
  }

  23.07692% {
    transform: translateX(-15vw) translateY(11vh) scale(0.63);
  }

  26.92308% {
    transform: translateX(14vw) translateY(-36vh) scale(0.9);
  }

  30.76923% {
    transform: translateX(20vw) translateY(31vh) scale(0.26);
  }

  34.61538% {
    transform: translateX(-17vw) translateY(-25vh) scale(0.88);
  }

  38.46154% {
    transform: translateX(40vw) translateY(0vh) scale(0.36);
  }

  42.30769% {
    transform: translateX(-42vw) translateY(-10vh) scale(0.7);
  }

  46.15385% {
    transform: translateX(-28vw) translateY(-4vh) scale(0.64);
  }

  50% {
    transform: translateX(-40vw) translateY(-41vh) scale(0.82);
  }

  53.84615% {
    transform: translateX(33vw) translateY(-5vh) scale(0.35);
  }

  57.69231% {
    transform: translateX(-39vw) translateY(50vh) scale(0.76);
  }

  61.53846% {
    transform: translateX(48vw) translateY(-24vh) scale(0.58);
  }

  65.38462% {
    transform: translateX(-44vw) translateY(39vh) scale(0.46);
  }

  69.23077% {
    transform: translateX(-31vw) translateY(6vh) scale(0.47);
  }

  73.07692% {
    transform: translateX(21vw) translateY(-27vh) scale(0.93);
  }

  76.92308% {
    transform: translateX(29vw) translateY(29vh) scale(0.6);
  }

  80.76923% {
    transform: translateX(-16vw) translateY(13vh) scale(0.83);
  }

  84.61538% {
    transform: translateX(23vw) translateY(-31vh) scale(0.77);
  }

  88.46154% {
    transform: translateX(-48vw) translateY(-30vh) scale(0.7);
  }

  92.30769% {
    transform: translateX(-28vw) translateY(36vh) scale(0.8);
  }

  96.15385% {
    transform: translateX(6vw) translateY(34vh) scale(0.29);
  }

  100% {
    transform: translateX(48vw) translateY(31vh) scale(0.97);
  }
}

.firefly:nth-child(6) {
  animation-name: move6;
}

.firefly:nth-child(6)::before {
  animation-duration: 11s;
}

.firefly:nth-child(6)::after {
  animation-duration: 11s, 6748ms;
  animation-delay: 0ms, 3069ms;
}

@keyframes move6 {
  0% {
    transform: translateX(-38vw) translateY(43vh) scale(0.56);
  }

  5% {
    transform: translateX(-28vw) translateY(32vh) scale(0.53);
  }

  10% {
    transform: translateX(7vw) translateY(-33vh) scale(0.32);
  }

  15% {
    transform: translateX(7vw) translateY(19vh) scale(0.42);
  }

  20% {
    transform: translateX(39vw) translateY(-17vh) scale(0.7);
  }

  25% {
    transform: translateX(-3vw) translateY(-3vh) scale(0.85);
  }

  30% {
    transform: translateX(-12vw) translateY(30vh) scale(0.79);
  }

  35% {
    transform: translateX(-22vw) translateY(-48vh) scale(0.73);
  }

  40% {
    transform: translateX(-33vw) translateY(-22vh) scale(0.31);
  }

  45% {
    transform: translateX(16vw) translateY(-7vh) scale(0.71);
  }

  50% {
    transform: translateX(-27vw) translateY(6vh) scale(0.99);
  }

  55% {
    transform: translateX(-14vw) translateY(-3vh) scale(0.89);
  }

  60% {
    transform: translateX(25vw) translateY(-18vh) scale(0.72);
  }

  65% {
    transform: translateX(-45vw) translateY(-1vh) scale(0.81);
  }

  70% {
    transform: translateX(9vw) translateY(-18vh) scale(0.64);
  }

  75% {
    transform: translateX(8vw) translateY(-44vh) scale(0.3);
  }

  80% {
    transform: translateX(-26vw) translateY(-14vh) scale(0.78);
  }

  85% {
    transform: translateX(26vw) translateY(-26vh) scale(0.85);
  }

  90% {
    transform: translateX(16vw) translateY(26vh) scale(0.26);
  }

  95% {
    transform: translateX(-10vw) translateY(-45vh) scale(0.43);
  }

  100% {
    transform: translateX(44vw) translateY(12vh) scale(0.88);
  }
}

.firefly:nth-child(7) {
  animation-name: move7;
}

.firefly:nth-child(7)::before {
  animation-duration: 12s;
}

.firefly:nth-child(7)::after {
  animation-duration: 12s, 10566ms;
  animation-delay: 0ms, 4342ms;
}

@keyframes move7 {
  0% {
    transform: translateX(1vw) translateY(-25vh) scale(0.59);
  }

  3.84615% {
    transform: translateX(42vw) translateY(24vh) scale(0.63);
  }

  7.69231% {
    transform: translateX(7vw) translateY(-11vh) scale(0.43);
  }

  11.53846% {
    transform: translateX(0vw) translateY(-29vh) scale(0.32);
  }

  15.38462% {
    transform: translateX(41vw) translateY(-30vh) scale(0.73);
  }

  19.23077% {
    transform: translateX(38vw) translateY(-7vh) scale(0.31);
  }

  23.07692% {
    transform: translateX(39vw) translateY(-28vh) scale(0.78);
  }

  26.92308% {
    transform: translateX(19vw) translateY(-39vh) scale(0.77);
  }

  30.76923% {
    transform: translateX(-15vw) translateY(-38vh) scale(0.37);
  }

  34.61538% {
    transform: translateX(27vw) translateY(-18vh) scale(0.66);
  }

  38.46154% {
    transform: translateX(-44vw) translateY(17vh) scale(0.32);
  }

  42.30769% {
    transform: translateX(-34vw) translateY(0vh) scale(0.95);
  }

  46.15385% {
    transform: translateX(-23vw) translateY(-46vh) scale(0.63);
  }

  50% {
    transform: translateX(3vw) translateY(47vh) scale(0.31);
  }

  53.84615% {
    transform: translateX(-40vw) translateY(-25vh) scale(0.4);
  }

  57.69231% {
    transform: translateX(26vw) translateY(-8vh) scale(0.94);
  }

  61.53846% {
    transform: translateX(45vw) translateY(-1vh) scale(0.28);
  }

  65.38462% {
    transform: translateX(14vw) translateY(-36vh) scale(0.86);
  }

  69.23077% {
    transform: translateX(32vw) translateY(-23vh) scale(0.54);
  }

  73.07692% {
    transform: translateX(0vw) translateY(-29vh) scale(0.69);
  }

  76.92308% {
    transform: translateX(15vw) translateY(40vh) scale(0.35);
  }

  80.76923% {
    transform: translateX(34vw) translateY(-26vh) scale(0.82);
  }

  84.61538% {
    transform: translateX(-14vw) translateY(-47vh) scale(0.73);
  }

  88.46154% {
    transform: translateX(-24vw) translateY(-19vh) scale(0.87);
  }

  92.30769% {
    transform: translateX(-33vw) translateY(-5vh) scale(0.41);
  }

  96.15385% {
    transform: translateX(-32vw) translateY(15vh) scale(0.48);
  }

  100% {
    transform: translateX(46vw) translateY(-39vh) scale(0.43);
  }
}

.firefly:nth-child(8) {
  animation-name: move8;
}

.firefly:nth-child(8)::before {
  animation-duration: 14s;
}

.firefly:nth-child(8)::after {
  animation-duration: 14s, 5708ms;
  animation-delay: 0ms, 3675ms;
}

@keyframes move8 {
  0% {
    transform: translateX(8vw) translateY(38vh) scale(0.49);
  }

  4.34783% {
    transform: translateX(42vw) translateY(-26vh) scale(0.63);
  }

  8.69565% {
    transform: translateX(-28vw) translateY(14vh) scale(0.41);
  }

  13.04348% {
    transform: translateX(23vw) translateY(44vh) scale(0.88);
  }

  17.3913% {
    transform: translateX(26vw) translateY(-32vh) scale(0.89);
  }

  21.73913% {
    transform: translateX(46vw) translateY(29vh) scale(0.42);
  }

  26.08696% {
    transform: translateX(-12vw) translateY(-28vh) scale(0.41);
  }

  30.43478% {
    transform: translateX(38vw) translateY(-30vh) scale(0.5);
  }

  34.78261% {
    transform: translateX(-8vw) translateY(22vh) scale(0.49);
  }

  39.13043% {
    transform: translateX(33vw) translateY(2vh) scale(0.66);
  }

  43.47826% {
    transform: translateX(-31vw) translateY(-7vh) scale(0.44);
  }

  47.82609% {
    transform: translateX(48vw) translateY(-49vh) scale(0.73);
  }

  52.17391% {
    transform: translateX(-22vw) translateY(19vh) scale(0.76);
  }

  56.52174% {
    transform: translateX(11vw) translateY(19vh) scale(0.26);
  }

  60.86957% {
    transform: translateX(-41vw) translateY(-22vh) scale(0.59);
  }

  65.21739% {
    transform: translateX(-32vw) translateY(-35vh) scale(0.71);
  }

  69.56522% {
    transform: translateX(21vw) translateY(-2vh) scale(0.33);
  }

  73.91304% {
    transform: translateX(-5vw) translateY(48vh) scale(0.68);
  }

  78.26087% {
    transform: translateX(-5vw) translateY(-46vh) scale(0.94);
  }

  82.6087% {
    transform: translateX(11vw) translateY(-22vh) scale(0.57);
  }

  86.95652% {
    transform: translateX(-35vw) translateY(-13vh) scale(0.76);
  }

  91.30435% {
    transform: translateX(-9vw) translateY(-49vh) scale(0.82);
  }

  95.65217% {
    transform: translateX(25vw) translateY(3vh) scale(0.83);
  }

  100% {
    transform: translateX(-19vw) translateY(16vh) scale(0.64);
  }
}

.firefly:nth-child(9) {
  animation-name: move9;
}

.firefly:nth-child(9)::before {
  animation-duration: 9s;
}

.firefly:nth-child(9)::after {
  animation-duration: 9s, 10194ms;
  animation-delay: 0ms, 7908ms;
}

@keyframes move9 {
  0% {
    transform: translateX(-34vw) translateY(-37vh) scale(0.62);
  }

  5.55556% {
    transform: translateX(16vw) translateY(38vh) scale(0.67);
  }

  11.11111% {
    transform: translateX(29vw) translateY(15vh) scale(0.53);
  }

  16.66667% {
    transform: translateX(-20vw) translateY(-30vh) scale(0.53);
  }

  22.22222% {
    transform: translateX(-38vw) translateY(-3vh) scale(0.5);
  }

  27.77778% {
    transform: translateX(28vw) translateY(46vh) scale(0.5);
  }

  33.33333% {
    transform: translateX(15vw) translateY(25vh) scale(0.26);
  }

  38.88889% {
    transform: translateX(7vw) translateY(-40vh) scale(0.6);
  }

  44.44444% {
    transform: translateX(-2vw) translateY(25vh) scale(0.93);
  }

  50% {
    transform: translateX(26vw) translateY(-47vh) scale(0.29);
  }

  55.55556% {
    transform: translateX(11vw) translateY(-34vh) scale(0.84);
  }

  61.11111% {
    transform: translateX(-15vw) translateY(1vh) scale(0.27);
  }

  66.66667% {
    transform: translateX(-36vw) translateY(-22vh) scale(0.89);
  }

  72.22222% {
    transform: translateX(11vw) translateY(21vh) scale(0.84);
  }

  77.77778% {
    transform: translateX(32vw) translateY(-7vh) scale(0.36);
  }

  83.33333% {
    transform: translateX(-8vw) translateY(32vh) scale(0.92);
  }

  88.88889% {
    transform: translateX(30vw) translateY(11vh) scale(0.76);
  }

  94.44444% {
    transform: translateX(-39vw) translateY(-29vh) scale(0.87);
  }

  100% {
    transform: translateX(39vw) translateY(-19vh) scale(0.53);
  }
}

.firefly:nth-child(10) {
  animation-name: move10;
}

.firefly:nth-child(10)::before {
  animation-duration: 12s;
}

.firefly:nth-child(10)::after {
  animation-duration: 12s, 10993ms;
  animation-delay: 0ms, 3243ms;
}

@keyframes move10 {
  0% {
    transform: translateX(30vw) translateY(28vh) scale(0.48);
  }

  4% {
    transform: translateX(-14vw) translateY(17vh) scale(0.45);
  }

  8% {
    transform: translateX(48vw) translateY(21vh) scale(0.43);
  }

  12% {
    transform: translateX(-40vw) translateY(-29vh) scale(0.68);
  }

  16% {
    transform: translateX(-26vw) translateY(-29vh) scale(0.62);
  }

  20% {
    transform: translateX(45vw) translateY(-27vh) scale(0.52);
  }

  24% {
    transform: translateX(26vw) translateY(31vh) scale(0.96);
  }

  28% {
    transform: translateX(26vw) translateY(6vh) scale(0.85);
  }

  32% {
    transform: translateX(46vw) translateY(-14vh) scale(0.95);
  }

  36% {
    transform: translateX(50vw) translateY(-47vh) scale(0.84);
  }

  40% {
    transform: translateX(25vw) translateY(-6vh) scale(0.62);
  }

  44% {
    transform: translateX(38vw) translateY(2vh) scale(1);
  }

  48% {
    transform: translateX(-15vw) translateY(11vh) scale(0.49);
  }

  52% {
    transform: translateX(-46vw) translateY(38vh) scale(0.69);
  }

  56% {
    transform: translateX(28vw) translateY(-7vh) scale(0.45);
  }

  60% {
    transform: translateX(-39vw) translateY(-45vh) scale(0.46);
  }

  64% {
    transform: translateX(7vw) translateY(31vh) scale(0.57);
  }

  68% {
    transform: translateX(41vw) translateY(34vh) scale(0.94);
  }

  72% {
    transform: translateX(39vw) translateY(-40vh) scale(0.89);
  }

  76% {
    transform: translateX(-25vw) translateY(5vh) scale(0.68);
  }

  80% {
    transform: translateX(16vw) translateY(-14vh) scale(0.34);
  }

  84% {
    transform: translateX(29vw) translateY(50vh) scale(0.26);
  }

  88% {
    transform: translateX(29vw) translateY(17vh) scale(0.36);
  }

  92% {
    transform: translateX(-39vw) translateY(-10vh) scale(0.43);
  }

  96% {
    transform: translateX(-46vw) translateY(-23vh) scale(0.74);
  }

  100% {
    transform: translateX(31vw) translateY(-1vh) scale(0.48);
  }
}

.firefly:nth-child(11) {
  animation-name: move11;
}

.firefly:nth-child(11)::before {
  animation-duration: 16s;
}

.firefly:nth-child(11)::after {
  animation-duration: 16s, 8742ms;
  animation-delay: 0ms, 7882ms;
}

@keyframes move11 {
  0% {
    transform: translateX(-3vw) translateY(39vh) scale(0.85);
  }

  5.26316% {
    transform: translateX(-33vw) translateY(43vh) scale(0.98);
  }

  10.52632% {
    transform: translateX(3vw) translateY(35vh) scale(0.96);
  }

  15.78947% {
    transform: translateX(10vw) translateY(-38vh) scale(0.8);
  }

  21.05263% {
    transform: translateX(-46vw) translateY(-48vh) scale(0.72);
  }

  26.31579% {
    transform: translateX(0vw) translateY(-45vh) scale(0.68);
  }

  31.57895% {
    transform: translateX(18vw) translateY(-12vh) scale(0.87);
  }

  36.84211% {
    transform: translateX(-6vw) translateY(46vh) scale(0.59);
  }

  42.10526% {
    transform: translateX(38vw) translateY(44vh) scale(0.69);
  }

  47.36842% {
    transform: translateX(41vw) translateY(-5vh) scale(0.65);
  }

  52.63158% {
    transform: translateX(36vw) translateY(-38vh) scale(0.65);
  }

  57.89474% {
    transform: translateX(-38vw) translateY(42vh) scale(0.65);
  }

  63.15789% {
    transform: translateX(-46vw) translateY(1vh) scale(0.69);
  }

  68.42105% {
    transform: translateX(13vw) translateY(32vh) scale(0.57);
  }

  73.68421% {
    transform: translateX(-44vw) translateY(44vh) scale(0.96);
  }

  78.94737% {
    transform: translateX(-25vw) translateY(-1vh) scale(0.74);
  }

  84.21053% {
    transform: translateX(-46vw) translateY(17vh) scale(0.94);
  }

  89.47368% {
    transform: translateX(-21vw) translateY(-11vh) scale(0.9);
  }

  94.73684% {
    transform: translateX(7vw) translateY(23vh) scale(0.98);
  }

  100% {
    transform: translateX(11vw) translateY(8vh) scale(0.85);
  }
}

.firefly:nth-child(12) {
  animation-name: move12;
}

.firefly:nth-child(12)::before {
  animation-duration: 10s;
}

.firefly:nth-child(12)::after {
  animation-duration: 10s, 6294ms;
  animation-delay: 0ms, 5490ms;
}

@keyframes move12 {
  0% {
    transform: translateX(41vw) translateY(0vh) scale(0.6);
  }

  4.34783% {
    transform: translateX(23vw) translateY(-23vh) scale(0.66);
  }

  8.69565% {
    transform: translateX(-47vw) translateY(-16vh) scale(0.5);
  }

  13.04348% {
    transform: translateX(16vw) translateY(-37vh) scale(0.77);
  }

  17.3913% {
    transform: translateX(3vw) translateY(8vh) scale(0.28);
  }

  21.73913% {
    transform: translateX(10vw) translateY(-27vh) scale(0.89);
  }

  26.08696% {
    transform: translateX(31vw) translateY(-21vh) scale(0.39);
  }

  30.43478% {
    transform: translateX(16vw) translateY(-7vh) scale(0.67);
  }

  34.78261% {
    transform: translateX(20vw) translateY(-28vh) scale(0.48);
  }

  39.13043% {
    transform: translateX(20vw) translateY(-31vh) scale(0.56);
  }

  43.47826% {
    transform: translateX(-29vw) translateY(34vh) scale(0.35);
  }

  47.82609% {
    transform: translateX(30vw) translateY(49vh) scale(0.52);
  }

  52.17391% {
    transform: translateX(47vw) translateY(21vh) scale(0.57);
  }

  56.52174% {
    transform: translateX(35vw) translateY(23vh) scale(0.77);
  }

  60.86957% {
    transform: translateX(40vw) translateY(-40vh) scale(0.93);
  }

  65.21739% {
    transform: translateX(-35vw) translateY(34vh) scale(0.58);
  }

  69.56522% {
    transform: translateX(12vw) translateY(-41vh) scale(0.86);
  }

  73.91304% {
    transform: translateX(1vw) translateY(-37vh) scale(0.53);
  }

  78.26087% {
    transform: translateX(39vw) translateY(-29vh) scale(0.38);
  }

  82.6087% {
    transform: translateX(50vw) translateY(15vh) scale(0.68);
  }

  86.95652% {
    transform: translateX(-36vw) translateY(49vh) scale(0.33);
  }

  91.30435% {
    transform: translateX(44vw) translateY(-5vh) scale(0.59);
  }

  95.65217% {
    transform: translateX(29vw) translateY(25vh) scale(0.4);
  }

  100% {
    transform: translateX(43vw) translateY(11vh) scale(0.64);
  }
}

.firefly:nth-child(13) {
  animation-name: move13;
}

.firefly:nth-child(13)::before {
  animation-duration: 12s;
}

.firefly:nth-child(13)::after {
  animation-duration: 12s, 5990ms;
  animation-delay: 0ms, 1212ms;
}

@keyframes move13 {
  0% {
    transform: translateX(-9vw) translateY(-39vh) scale(0.6);
  }

  4.34783% {
    transform: translateX(47vw) translateY(-49vh) scale(0.73);
  }

  8.69565% {
    transform: translateX(19vw) translateY(-12vh) scale(0.88);
  }

  13.04348% {
    transform: translateX(31vw) translateY(3vh) scale(0.82);
  }

  17.3913% {
    transform: translateX(22vw) translateY(-34vh) scale(0.88);
  }

  21.73913% {
    transform: translateX(-18vw) translateY(8vh) scale(0.5);
  }

  26.08696% {
    transform: translateX(36vw) translateY(39vh) scale(0.59);
  }

  30.43478% {
    transform: translateX(-36vw) translateY(-26vh) scale(0.36);
  }

  34.78261% {
    transform: translateX(9vw) translateY(-3vh) scale(0.68);
  }

  39.13043% {
    transform: translateX(-21vw) translateY(43vh) scale(0.73);
  }

  43.47826% {
    transform: translateX(-42vw) translateY(28vh) scale(0.61);
  }

  47.82609% {
    transform: translateX(-24vw) translateY(35vh) scale(0.87);
  }

  52.17391% {
    transform: translateX(-10vw) translateY(14vh) scale(0.76);
  }

  56.52174% {
    transform: translateX(-3vw) translateY(-1vh) scale(0.46);
  }

  60.86957% {
    transform: translateX(12vw) translateY(-27vh) scale(0.98);
  }

  65.21739% {
    transform: translateX(-47vw) translateY(22vh) scale(0.82);
  }

  69.56522% {
    transform: translateX(-19vw) translateY(-49vh) scale(0.34);
  }

  73.91304% {
    transform: translateX(-1vw) translateY(-48vh) scale(0.81);
  }

  78.26087% {
    transform: translateX(-16vw) translateY(47vh) scale(0.62);
  }

  82.6087% {
    transform: translateX(36vw) translateY(18vh) scale(0.37);
  }

  86.95652% {
    transform: translateX(-1vw) translateY(-4vh) scale(0.95);
  }

  91.30435% {
    transform: translateX(-32vw) translateY(2vh) scale(0.54);
  }

  95.65217% {
    transform: translateX(23vw) translateY(39vh) scale(0.26);
  }

  100% {
    transform: translateX(-17vw) translateY(-47vh) scale(0.31);
  }
}

.firefly:nth-child(14) {
  animation-name: move14;
}

.firefly:nth-child(14)::before {
  animation-duration: 12s;
}

.firefly:nth-child(14)::after {
  animation-duration: 12s, 8024ms;
  animation-delay: 0ms, 8199ms;
}

@keyframes move14 {
  0% {
    transform: translateX(35vw) translateY(-25vh) scale(0.59);
  }

  3.7037% {
    transform: translateX(18vw) translateY(-11vh) scale(0.4);
  }

  7.40741% {
    transform: translateX(-46vw) translateY(18vh) scale(0.4);
  }

  11.11111% {
    transform: translateX(-32vw) translateY(-5vh) scale(0.71);
  }

  14.81481% {
    transform: translateX(-5vw) translateY(44vh) scale(0.99);
  }

  18.51852% {
    transform: translateX(30vw) translateY(-2vh) scale(0.31);
  }

  22.22222% {
    transform: translateX(31vw) translateY(-14vh) scale(0.5);
  }

  25.92593% {
    transform: translateX(11vw) translateY(-9vh) scale(1);
  }

  29.62963% {
    transform: translateX(-21vw) translateY(21vh) scale(0.57);
  }

  33.33333% {
    transform: translateX(28vw) translateY(-4vh) scale(0.48);
  }

  37.03704% {
    transform: translateX(-49vw) translateY(-5vh) scale(0.34);
  }

  40.74074% {
    transform: translateX(8vw) translateY(36vh) scale(0.82);
  }

  44.44444% {
    transform: translateX(-27vw) translateY(4vh) scale(0.68);
  }

  48.14815% {
    transform: translateX(-45vw) translateY(39vh) scale(0.43);
  }

  51.85185% {
    transform: translateX(3vw) translateY(22vh) scale(0.54);
  }

  55.55556% {
    transform: translateX(-4vw) translateY(-35vh) scale(0.58);
  }

  59.25926% {
    transform: translateX(6vw) translateY(35vh) scale(0.64);
  }

  62.96296% {
    transform: translateX(15vw) translateY(32vh) scale(0.38);
  }

  66.66667% {
    transform: translateX(-41vw) translateY(45vh) scale(0.95);
  }

  70.37037% {
    transform: translateX(37vw) translateY(11vh) scale(0.88);
  }

  74.07407% {
    transform: translateX(-33vw) translateY(23vh) scale(0.39);
  }

  77.77778% {
    transform: translateX(-27vw) translateY(0vh) scale(0.61);
  }

  81.48148% {
    transform: translateX(5vw) translateY(20vh) scale(0.42);
  }

  85.18519% {
    transform: translateX(-15vw) translateY(-32vh) scale(0.32);
  }

  88.88889% {
    transform: translateX(20vw) translateY(-48vh) scale(0.47);
  }

  92.59259% {
    transform: translateX(-26vw) translateY(-41vh) scale(0.6);
  }

  96.2963% {
    transform: translateX(-21vw) translateY(42vh) scale(0.9);
  }

  100% {
    transform: translateX(-15vw) translateY(9vh) scale(0.5);
  }
}

.firefly:nth-child(15) {
  animation-name: move15;
}

.firefly:nth-child(15)::before {
  animation-duration: 9s;
}

.firefly:nth-child(15)::after {
  animation-duration: 9s, 8571ms;
  animation-delay: 0ms, 2981ms;
}

@keyframes move15 {
  0% {
    transform: translateX(-34vw) translateY(17vh) scale(0.96);
  }

  3.7037% {
    transform: translateX(-15vw) translateY(10vh) scale(0.5);
  }

  7.40741% {
    transform: translateX(-43vw) translateY(-25vh) scale(0.41);
  }

  11.11111% {
    transform: translateX(-18vw) translateY(16vh) scale(0.32);
  }

  14.81481% {
    transform: translateX(17vw) translateY(-41vh) scale(0.57);
  }

  18.51852% {
    transform: translateX(0vw) translateY(-10vh) scale(0.7);
  }

  22.22222% {
    transform: translateX(8vw) translateY(46vh) scale(0.92);
  }

  25.92593% {
    transform: translateX(-31vw) translateY(14vh) scale(0.28);
  }

  29.62963% {
    transform: translateX(49vw) translateY(26vh) scale(0.85);
  }

  33.33333% {
    transform: translateX(16vw) translateY(-48vh) scale(1);
  }

  37.03704% {
    transform: translateX(-39vw) translateY(-26vh) scale(0.32);
  }

  40.74074% {
    transform: translateX(-14vw) translateY(-25vh) scale(0.43);
  }

  44.44444% {
    transform: translateX(-27vw) translateY(43vh) scale(0.66);
  }

  48.14815% {
    transform: translateX(-32vw) translateY(48vh) scale(0.83);
  }

  51.85185% {
    transform: translateX(-20vw) translateY(20vh) scale(0.59);
  }

  55.55556% {
    transform: translateX(-17vw) translateY(-44vh) scale(0.35);
  }

  59.25926% {
    transform: translateX(-48vw) translateY(-11vh) scale(0.83);
  }

  62.96296% {
    transform: translateX(10vw) translateY(-43vh) scale(0.85);
  }

  66.66667% {
    transform: translateX(9vw) translateY(-10vh) scale(0.72);
  }

  70.37037% {
    transform: translateX(-37vw) translateY(39vh) scale(0.79);
  }

  74.07407% {
    transform: translateX(37vw) translateY(4vh) scale(0.81);
  }

  77.77778% {
    transform: translateX(27vw) translateY(43vh) scale(0.72);
  }

  81.48148% {
    transform: translateX(50vw) translateY(3vh) scale(0.57);
  }

  85.18519% {
    transform: translateX(47vw) translateY(-40vh) scale(0.72);
  }

  88.88889% {
    transform: translateX(17vw) translateY(-45vh) scale(0.77);
  }

  92.59259% {
    transform: translateX(39vw) translateY(-38vh) scale(0.81);
  }

  96.2963% {
    transform: translateX(45vw) translateY(27vh) scale(0.63);
  }

  100% {
    transform: translateX(14vw) translateY(26vh) scale(0.82);
  }
}

@keyframes drift {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes flash {

  0%,
  30%,
  100% {
    opacity: 0;
    box-shadow: 0 0 0vw 0vw yellow;
  }

  5% {
    opacity: 1;
    box-shadow: 0 0 2vw 0.4vw yellow;
  }
}

.sun {
  width: 10%;
  height: 20%;
  position: absolute;
  background: radial-gradient(transparent 50%, white),
    radial-gradient(rgb(241, 126, 126), transparent 70%);
  -webkit-mask-image: radial-gradient(black 40%, transparent 65%);
  /* mask-image: radial-gradient(black 40%, transparent 65%); */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  top: 15%;
  right: 12%;
}

.sun::after,
.sun::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: center;
  border-radius: 50%;
  -webkit-mask-image: radial-gradient(black 40%, transparent 65%);
  mask-image: radial-gradient(black 40%, transparent 65%);
}

.sun::before {
  background: repeating-conic-gradient(from 0deg,
      #e5b2e8 0deg 20deg,
      transparent 20deg 40deg);
  animation: rotate 720s linear, scale 3s linear infinite;
}

.sun::after {
  transform: rotate(15deg);
  animation: rotate 360s linear;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes scale {
  0% {
    transform: scale(1);
  }

  20% {
    transform: scale(1.2);
  }

  40% {
    transform: scale(0.8);
  }

  60% {
    transform: scale(1.1);
  }

  70% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}