.container_Dashboard {
  width: 100%;
  min-height: 90vh;
  background: rgba(255, 255, 255, 0.795);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.content_Dashboard {
  width: 80%;
  height: 85%;
}

.dashboard_title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #b22222;
  justify-content: center;
}

.dashboard_title img {
  width: 9%;
  padding: 4px 1px;
}

.AdminPMS {
  overflow-y: auto;
  overflow-x: hidden;
}

.list_user {
  width: 20%;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  background: #edf2f7;
  border-radius: 15px;
  gap: 10px;
  padding: 10px;
}

@media screen and (min-width: 1280px) {
  .content_Dashboard {
    height: 95%;
  }
}
