.container {
  background-image: url("../../assets//background/backgound_draf.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.textareaWrapper {
  position: relative;
  width: 100%;
  height: 75%;
  margin-top: 5%;
}

.textareaWrapper textarea {
  border: none;
  padding: 5px;
  font-size: 16px;
  word-wrap: break-word;
  resize: vertical;
  overflow-wrap: break-word;
  background: #f3e9d3;
  line-height: 2.8vh;
  background-size: 100% 20px;
  background-clip: content-box, border-box;
  width: 100%;
  height: 90% !important;
  outline: none;
  resize: none;
}

textarea::-webkit-scrollbar {
  width: 8px;
}

textarea::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

textarea::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.content_icon {
  width: 80%;
  display: flex;
  justify-content: space-between;
}

.content_icon span {
  font-size: 12px;
}

.btnStatusStart {
  border-radius: 5px;
  padding: 5px;
  width: 100px;
  text-align: center;
  color: white;
  background-color: #007aff;
}

.btnStatusWriting {
  border-radius: 5px;
  padding: 5px;
  width: 100px;
  text-align: center;
  color: white;
  background-color: red;
}

@media screen and (min-width: 1024px) {
  .textareaWrapper textarea {
    font-size: 15px;
  }
}

@media screen and (min-width: 1280px) {
  .container {
    height: 95%;
  }

  .textareaWrapper {
    height: 70%;
    width: 90%;
  }
}

@media screen and (min-width: 1280px) {
  .textareaWrapper textarea {
    font-size: 17px;
  }
}

@media screen and (min-width: 1920px) {
  .textareaWrapper textarea {
    font-size: 22px;
  }
}
