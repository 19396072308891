.container_Dashboard {
  width: 100%;
  /* min-height: 90vh; */
  height: auto;
  padding-top: 5rem;
  padding-bottom: 5rem;
  background: rgba(255, 255, 255, 0.795);
  display: flex;
  justify-content: center;
  align-items: center;
}

.content_Dashboard {
  width: 80%;
  height: 85%;
}

.dashboard_title {
  font-family: "Roboto";
  display: inherit;
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #b22222;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

@media screen and (min-width: 1280px) {
  .content_Dashboard {
    width: 90%;
  }

  .dashboard_title {
    font-size: 50px;
  }
}

@media screen and (min-width: 1440px) {
}

@media screen and (min-width: 1920px) {
}
