.container_inputChat {
  /* min-height: 10%;
  height: 70px; */
  width: 60%;
  gap: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.textareaScrollbar::-webkit-scrollbar {
  width: none;
}

.container_inputChat textarea {
  /* padding-right: 80px; */
}

.container_inputChat .btnSend {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 35px;
  height: 35px;
  background: #37cdbc;
  border-radius: 12px;
  border: none;
  transition: 0.2s;
}
.microphone {
  background: transparent;
  border: none;
}

.container_inputChat .btnSend:hover {
  background: #2aa799;
}

.btnSend {
  height: 100%;
}

.inputForm {
  display: flex;
  justify-content: center;
  align-items: start;
  height: auto;
  width: 94%;
  position: relative;
  white-space: pre;
  word-break: break-word;
  align-items: center;
}

.inputForm .inputChat {
  color: #000;
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 2px solid #e2e2e2;
  border-radius: 8px;
  padding: 10px 24px;
  resize: none;
  height: 44px;
  transition: 0.2s;
}

.inputForm .inputChat:hover {
  outline: none;
  border: 2px solid#2AA799;
}

.inputForm .inputChat:focus {
  outline: none;
  border: 2px solid#2AA799;
}

@media only screen and (max-width: 900px) {
  .container_inputChat {
    margin-bottom: 0px;
  }
}
