.container {
  background: url("../../../assets/background/PetRoom.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.content {
  width: 90%;
  height: 80%;
  z-index: 0;
}

.content_user {
  /* background: url("../../../assets/background/background_petroom_inforUser.png"); */
  background-size: 100% 100%;
  width: 80%;
  background-repeat: no-repeat;
  height: 10vh;
  display: flex;
  flex-direction: row;
  text-align: center;
  position: absolute;
  justify-content: space-around;
  top: 3%;
  left: 7%;
}
.content_inforUSer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
  font-size: 16px;
  white-space: nowrap; /* Prevent text from wrapping to the next line */
  overflow: hidden; /* Hide the overflowing text */
  text-overflow: ellipsis;
}

.inforUser_name {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 14px;
  background: linear-gradient(139.36deg, #6b7aff -0.03%, #1430c6 120.49%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* text-fill-color: transparent; */
}
.inforUser_poin {
  font-family: "Salsa";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #ffdb53;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.inforUser_poin img {
  width: 35px;
}

.content_userAvar {
  width: 85%;
  position: relative;
}

.content_userAvar_right {
  position: relative;
  width: 15%;
  right: 0px;
  display: flex;
  height: 70px;
  justify-items: flex-end;
}

.content_userAvar_right .avatarIconPet {
  width: 70px;
  height: 70px;
  left: 14px;
  position: absolute;
  background-color: white;
  border: 1px solid #5ba3b9;
  border-radius: 50%;
  overflow: hidden;
  z-index: 1;
}

.content_userAvar_right .avatarIconPet img {
  width: 85%;
  margin: 0 4px;
}

.content_userAvar_right .boxTitle {
  position: absolute;
  width: 322px;
  height: 70px;
  top: 0px;
  left: 20px;
  /* gra logo */

  background: url("https://writing.ac/static/media/background_petroom_inforUser.3424e8c0734197f736ac.png");
  box-shadow: 0px 13px 29.3236px #d9d9d9, inset 0px -6px 14px rgba(0, 0, 0, 0.1);
  border-radius: 106px;
}

.content_userAvar_right .textTitleUserName {
  position: absolute;
  width: 300px;
  top: 5px;
  left: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 2;
  font-family: "Salsa";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  /* identical to box height, or 87% */

  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  background: linear-gradient(139.36deg, #6b7aff -0.03%, #1430c6 120.49%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* text-fill-color: transparent; */

  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.25);
}

.content_userAvar_right .medalStart {
  width: 24%;
  position: absolute;
  top: 20px;
  left: 99px;
}

.content_userAvar_right .userPoint {
  position: absolute;
  top: 24px;
  left: 148px;
  margin: 0 -17px;
  font-style: normal;
  font-weight: 400;
  font-size: 33px;
  line-height: 24px;
  /* identical to box height, or 48% */

  display: flex;
  align-items: center;

  color: #f3d14f;
}

.content_userAvar_right .point {
  position: absolute;
  top: 24px;
  left: 225px;
  margin: 0 -17px;
  font-style: normal;
  font-weight: 400;
  font-size: 33px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #f3d14f;
}

.content_userAvar .iconTitle {
  width: 99px;
  height: 107px;
  position: absolute;
  left: -50px;
  top: -18px;
  z-index: 2;
}

.content_userAvar .boxTitle {
  width: 200px;
  height: 60px;
  left: 11px;
  top: 0;
  transform: rotate(0deg) skew(330deg);
  transform-origin: 50% 50%; /* Điểm trung tâm xoay */
  position: absolute;
  background: linear-gradient(
    274.68deg,
    #65bfcc 5.61%,
    #5bdaec 47.54%,
    #0cc1db 89.47%
  );
  border: 1px solid none;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(143, 42, 42, 0.5); /* Hiệu ứng box shadow */
  z-index: 1;
}

.content_userAvar .textTitle {
  font-family: "Salsa";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 50px;
  width: 200px;
  /* identical to box height, or 167% */
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 1;
  top: 5px;
  left: 35px;
  color: #ffffff;
}

.content_user .title_petroom {
  position: absolute;
  width: 229px;
  height: 75px;
  left: 192px;
  top: 169px;

  /* background: linear-gradient(274.68deg, #65BFCC 5.61%, #5BDAEC 47.54%, #0CC1DB 89.47%);
  border-radius: 10px;   */
}

.btn_home {
  position: absolute;
  right: 8%;
  top: 2%;
  cursor: pointer;
}
.btn_mic {
  position: absolute;
  bottom: 0%;
  right: -10%;
}

.content_listPet {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.animationSticker {
  position: absolute;
  top: 10%;
  left: 0px;
  display: flex;
  width: 25%;
}

.animationSticker > img {
  width: 80px;
  margin-right: 7px;
}

.medal {
  width: 80%;
}

.animationSticker .popUpType1 {
  position: absolute;
  top: 84px;
  left: 0px;
  width: 70%;
  height: 50vh;
  background: #ffffff;
  border: 9px solid #f4869b;
  border-radius: 20px;
  box-shadow: 0px 23px 29.3236px #f4869b, inset 0px -4px 13px #f4869b;
}
.boxFirstType1 {
  margin-left: 3px;
  margin-top: -69px;
  width: 0;
  height: 0;
  border: 34px solid transparent;
  border-bottom-color: #f4869b;
}

.boxLastType1 {
  margin-left: 17px;
  margin-top: -38px;
  width: 0;
  height: 0;
  border: 21px solid transparent;
  border-bottom-color: #ffffff;
}

.animationSticker .popUpType2 {
  position: absolute;
  top: 84px;
  left: 0px;
  width: 70%;
  height: 50vh;
  background: #ffffff;
  border: 9px solid rgba(203, 150, 230, 0.42);
  border-radius: 30px;
  box-shadow: 0px 23px 29.3236px #dfade9, inset 0px -4px 13px #dfade9;
}
.boxFirstType2 {
  margin-left: 83px;
  margin-top: -67px;
  width: 0;
  height: 0;
  border: 34px solid transparent;
  border-bottom-color: #e9d3f5;
}

.boxLastType2 {
  margin-left: 97px;
  margin-top: -38px;
  width: 0;
  height: 0;
  border: 21px solid transparent;
  border-bottom-color: #ffffff;
}

.animationSticker .popUpType3 {
  position: absolute;
  top: 84px;
  left: -5px;
  width: 100%;
  height: 55vh;
  background: #ffffff;
  border: 9px solid rgba(0, 0, 0, 0.05);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.boxFirstType3 {
  border-radius: 6px;
  margin-left: 182px;
  margin-top: -166px;
  width: 0;
  height: 0;
  border: 25px solid transparent;
  border-bottom-color: #73d3d3;
}

.boxLastType3 {
  margin-left: 194px;
  margin-top: -30px;
  width: 0;
  height: 0;
  height: 0;
  border: 13px solid transparent;
  border-bottom-color: #fff;
}

.animationSticker .contenPopUp {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.animationSticker .contenPopUp .item {
  width: 50%;
}

.animationSticker .BtnClose {
  font-size: 25px;
  color: rgba(47, 48, 53, 0.2);
  width: 100%;
  display: flex;
  justify-content: end;
  position: absolute;
  top: 0px;
}

.content_animations {
  position: absolute;
  /* left: 4%; */
  /* bottom: 2%; */
  width: 25%;
  height: 35vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 2px solid white;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
  -webkit-backdrop-filter: blur(42px);
  backdrop-filter: blur(42px);
  border-radius: 25px;
}
.list_animations {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn_animation {
  width: 150px;
}

.slide_pets {
  /* background: url("../../../assets/background/Frame.png"); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 50%;
  height: 20vh;
  position: absolute;
  bottom: 2%;
  left: 32%;
}

.boxListOwnedPet {
  /* background-image: url("../../../assets/background/Frame.png"); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 48%;
  height: 23%;
  overflow: hidden;
  position: absolute;
  top: 86%;
  display: flex;
  justify-content: center;
}

.boxListOwnedPet .bg {
  position: absolute;
  width: 100%;
  height: 100%;
}

.listFrame {
  position: relative;
  padding-top: 3.5%;
}

.boxPet {
  display: flex;
  justify-content: center;
  border-radius: 20px;
  width: 50%;
  height: 50%;
  background: #fbbc51;
}

.namePet {
  padding-top: 14px;
  width: 100%;
  z-index: 1;
  font-family: "Salsa";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 28px;
  display: flex;
  justify-content: center;
  background: linear-gradient(139.36deg, #376386 -0.03%, #376386 120.49%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-shadow: 0px 8px 10px 0px rgba(101, 191, 204, 0.42);
}

.bgModel2D {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.boxImage {
  width: 45%;
  margin: auto;
  height: 99%;
}

.boxImage .pet2D {
  width: 100%;
  height: 87%;
  position: relative;
}

.alertChange3D {
  width: 200px;
  height: 100px;
  position: absolute;
  background: #eb5757;
  box-shadow: 0px 23px 29.3236px rgba(69, 90, 227, 0.2);
  top: 200%;
  z-index: 1;
  border-radius: 30px;
  color: white;
  font-family: "Salsa";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  padding-top: 20px;
  text-align: center;
  margin-top: -35px;
  margin-left: -23px;
  /* or 150% */
}

.box {
  margin: -147px 6px;
  width: 0;
  height: 0;
  border: 50px solid transparent;
  border-bottom-color: #eb5757;
}

/* .swChange3D {
  top: 90%;
  left: 0;
  transform: translateX(100%);
  position: absolute;
  z-index: 1;
} */

.heart {
  position: absolute;
  /* width: 150px;
  height: 140px; */
  width: 40%;
  height: 40%;
  animation: scale 2s infinite;
}
.icon {
  width: 100%;
  height: 100%;
}
/* .heart img {
  box-shadow: 0 0 5px 2px rgb(241, 126, 126);
} */

/* .heart::before,
.heart::after {
  content: "";
  position: absolute;
  top: 40px;
  width: 52px;
  height: 80px;
  border-radius: 50px 50px 0 0; 
  background: rgb(231, 123, 123);

  box-shadow: 0 0 5px 2px rgb(241, 126, 126);
}

.heart::before {
  left: 50px;
  transform: rotate(-45deg); 
  transform-origin: 0 100%;
}

.heart::after {
  left: 0;
  transform: rotate(45deg); 
  transform-origin: 100% 100%;
} */

@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

@media only screen and (max-width: 991px) {
  .boxTitle {
    width: 200px !important;
  }
  .textTitleUserName {
    font-size: 15px !important;
    transform: translate(100%, 50%);
    position: absolute;
    right: 50%;
  }
  .medal {
    width: 25px;
  }
  .userPoint {
    font-size: 20px !important;
  }
  .boxImage {
    width: 50%;
  }
  .pet2D {
    object-fit: contain !important;
  }
  .StickerIcon,
  .kingIcon,
  .ChatMicroIcon {
    width: 80px !important;
    object-fit: cover;
  }
  .animationSticker {
    margin-top: 10px;
  }
  .boxListOwnedPet {
    width: 100%;
    left: 0;
  }
  .petIcon {
    width: 70%;
  }
  .namePet {
    font-size: 50px;
    top: 0;
  }
  .angry {
    width: 80px;
  }
  .textTitle {
    left: 70px !important;
    top: 15px !important;
  }
}

.switch2D {
  position: absolute;
  display: flex;
  top: 100%;
  z-index: 10;
  left: 49%;
  padding-top: 12px;
  right: -30px;
}

span.ant-switch-inner-checked {
  padding: 13px 12px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.boxsticker {
  padding: 13px;
  color: rgba(237, 124, 160, 0.42);
  text-align: center;
  text-shadow: 0px 8px 10px 0px rgba(237, 124, 160, 0.42);
  font-size: 30px;
  font-family: Salsa;
  line-height: 30px;
  letter-spacing: 2.4px;
}

.comingSoon {
  margin-left: -141px;
  margin-bottom: -100%;
  color: #ccc;
  display: inherit;
  text-align: center;
  font-size: 1.5rem;
}

/* Display 1024px */
@media only screen and (width: 1024px) {
  .animationSticker .popUpType1 {
    position: absolute;
    top: 84px;
    left: 0px;
    width: 107%;
    height: 50vh;
    background: #ffffff;
    border: 9px solid #f4869b;
    border-radius: 20px;
    box-shadow: 0px 23px 29.3236px #f4869b, inset 0px -4px 13px #f4869b;
  }

  .animationSticker .popUpType2 {
    position: absolute;
    top: 83px;
    left: 1px;
    width: 105%;
    height: 46vh;
    background: #ffffff;
    border: 9px solid rgba(203, 150, 230, 0.42);
    border-radius: 30px;
    box-shadow: 0px 23px 29.3236px #dfade9, inset 0px -4px 13px #dfade9;
  }

  .animationSticker .popUpType3 {
    margin: 0 14px;
  }

  .boxFirstType3 {
    margin-left: 81%;
    margin-top: -66%;
  }
  .boxLastType3 {
    margin-left: 185px;
  }

  .content_userAvar_right .textTitleUserName {
    width: 125%;
  }

  .content_userAvar_right .boxTitle {
    width: 195%;
  }

  .content_userAvar_right .avatarIconPet {
    width: 100px;
    height: 100px;
  }

  .content_userAvar_right .userPoint {
    left: 118px;
  }

  .content_userAvar_right .point {
    left: 191px;
  }
  /* .content_userAvar_right .avatarIconPet img {
    width: 94%;
    margin: 3px 1px;
  } */
}

@media screen and (width: 1024px) {
  .switch2D {
    left: 28px;
  }
  .content_userAvar_right .medalStart {
    top: 22px;
    left: 74px;
    width: 40px;
  }

  .boxListOwnedPet {
    top: 88%;
    margin-right: -2%;
  }

  .content_userAvar_right {
    margin-left: -3%;
  }

  .listFrame {
    width: 100%;
    padding-top: 4.5%;
  }

  .boxPet {
    height: 70px;
  }

  .namePet {
    margin-left: 26%;
    margin-top: 5px;
  }
}

/* Display 1280 */
@media screen and (width: 1280px) {
  .listFrame {
    width: 100%;
    padding-top: 6.5%;
  }
  .switch2D {
    left: 32px;
  }
  .content_userAvar_right .boxTitle {
    height: 100%;
    width: 100%;
    padding: 0 130px;
  }
  .content_userAvar_right .avatarIconPet img {
    width: 66%;
    margin: 9px 8px;
  }
  .content_userAvar_right .point {
    left: 222px;
  }

  .content_userAvar_right .medalStart {
    left: 72px;
  }

  .content_userAvar_right .userPoint {
    left: 120px;
  }

  .content_userAvar_right .point {
    left: 195px;
  }

  .namePet {
    margin-left: 32%;
    margin-top: 4px;
  }
}

/* Display 1444px */
@media only screen and (max-width: 1440px) {
}
