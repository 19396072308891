/* .ant-table-filter-column .active {
  height: auto;
}


.ant-table-filter-dropdown {
  width: 22vw;
  height: 8vh;
  background-color: #71cfcf !important;
}

:where(.css-dev-only-do-not-override-12upa3x).ant-table-wrapper
  .ant-table-pagination.ant-pagination {
  margin: 22px -45%;
}

.tableCourse {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.table_Courese {
  font-size: 13px !important;
}

.table_approvalRequestAdmin {
  font-size: 13px !important;
}

.table-ClassApproval {
  font-size: 14px !important;
  overflow-y: auto !important;
}

.tableLMS_Class {
  font-size: 14px !important;
}

.tableEbook_Management {
  font-size: 14px !important;
  overflow-y: auto !important;
} */

.ant-table-filter-dropdown-btns .ant-btn-primary {
  background-color: #1677ff;
}

.ant-pagination-options {
  display: none !important;
}

.ant-popconfirm-buttons .ant-btn-primary {
  background-color: #1677ff;
}

.ant-modal-footer .ant-btn-primary {
  background-color: #1677ff;
}
