span.ant-form-item-feedback-icon.ant-form-item-feedback-icon-success {
  display: flex;
  align-items: center;
}

span.ant-form-item-feedback-icon.ant-form-item-feedback-icon-error {
  display: flex;
}

span.ant-form-item-feedback-icon.ant-form-item-feedback-icon-loading {
  display: flex;
}

.ant-form-item-explain-error {
  font-size: 10px;
}

.ant-col.ant-form-item-label.css-dev-only-do-not-override-1wazalj {
  padding: unset;
}

@media only screen and (min-width: 1280px) {
  .ant-form-item-explain-error {
    font-size: 14px;
  }
}
