.container {
  background: url("../../../assets/background/backgound_ranking.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  width: 80%;
  height: 80%;
  background: url("../../../assets/background/backgound_home1.png");
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
  -webkit-backdrop-filter: blur(42px);
  backdrop-filter: blur(42px);
  border-radius: 30px;
  z-index: 0;
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
  flex-direction: column;
}

.img_1,
.img_2 {
  position: absolute;
  z-index: 1;
}
.img_1 {
  bottom: -10%;
  right: -8%;
  width: 25%;
}
.img_2 {
  top: -15%;
  left: -10%;
  width: 25%;
}

.comingSoon {
  padding: 20%;
  color: #376386;
  text-align: center;
  font-family: Salsa;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.rank {
  position: relative;
}
.title {
  margin-top: 20px;
}

.medal {
  position: absolute;
  transform: translate(-30%, 0%);
  left: 0;
}
.user {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  top: 0;
  width: 100%;
  height: 82px;
}
.userAvatar {
  background-color: white;
  border-radius: 50%;
}
.userAvatar img {
  display: block;
}
.userLevel {
  color: #ffffff;
}
.userName span {
  -webkit-text-stroke: 1px #a4aef9;
  color: white;
  text-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.userPoin {
  font-size: 40px;
  color: #ffffff;
}
.medal_start {
  font-size: 40px;
  margin: 0 10px;
}
.userSt {
  position: absolute;
  color: white;
  font-size: 20px;
  right: 50%;
  top: 0;
  transform: translate(50%, 100%);
}

@media only screen and (max-width: 991px) {
  .img_2 {
    width: 50%;
    left: -17%;
  }
  .title {
    width: 50%;
    margin-top: 16%;
  }
  .rank {
    width: 100%;
    max-width: 500px;
  }
  .medal img {
    width: 100px;
  }
  .userSt {
    position: absolute;
    color: white;
    font-size: 20px;
    top: 0;
    transform: translate(50%, 50%);
  }
  .medal {
    top: 50%;
    position: absolute;
    transform: translate(-45%, -70%);
    margin-right: 20px;
    left: 0;
  }
  .user {
    transform: translateY(-18%);
  }
  .userAvatar img {
    width: 50px;
  }
}
@media only screen and (min-width: 1024px) {
  .rank img {
    height: 70px;
    width: 100%;
  }
  .medal {
    transform: translate(-50%, -20%);
  }
  .user {
    height: auto;
  }
  .userSt {
    position: absolute;
    color: white;
    font-size: 20px;
    right: 50%;
    top: 0;
    transform: translate(50%, 50%);
  }
  .userPoin {
    font-size: 20px;
  }
  .medal_start {
    font-size: 20px;
  }
}
