.ant-table-wrapper .ant-table-thead > tr > th {
  background: rgb(130, 255, 249);
  background: linear-gradient(
    16deg,
    rgba(130, 255, 249, 0.8715861344537815) 100%,
    rgba(246, 247, 245, 0.773546918767507) 100%,
    rgba(53, 121, 9, 0) 100%
  );
  color: white;
}

.custom-table thead {
  border-radius: 50px;
}

.btn_home {
  position: absolute;
  top: -30%;
  right: -5%;
}

table::-webkit-scrollbar {
  width: 8px;
  display: block;
}

table::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

table::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.tableChildren {
  font-size: 16px;
}

/*  */

.childTable div {
  background-color: unset !important;
  padding: unset !important;
  margin: unset !important;
}
.childTable thead tr th {
  background-color:#386386 !important;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}
