.container {
  background: url("../../../assets/background/background_petroom.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container::-webkit-scrollbar {
  display: none;
}

.content {
  width: 90%;
  height: 95%;
}

.content_point {
  background: url("../../../assets/background/background_petroom_inforUser.png");
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
}

.imagesLeft {
  width: 70%;
}

.inforUser_poin {
  font-family: "Salsa";
  font-style: normal;
  font-weight: 400;
  font-size: 1.85rem;
  color: red;
  display: flex;
  align-items: center;
  padding: 0.8rem 1.5rem;
}

.inforUser_poin img {
  width: 42px;
  height: 42px;
  margin-right: 5px;
}
.cardPet {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.07) 0%,
    rgba(255, 255, 255, 0.694906) 100%
  );
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(42px);
}
.cardPet_Owned,
.cardPet_notOwned,
.cardPet_canGet {
  font-family: "Salsa";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 30px;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid white;
  border-radius: 21px;
}

.cardPet_notOwned {
}

.cardPet_Owned {
}

.cardPet_canGet {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.07) 0%,
    #00ffa3 99.99%,
    rgba(255, 255, 255, 0.79) 100%
  );
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(42px);
}

.btnOrder {
  width: 120px;
  height: 40px;
  background: linear-gradient(
    139.36deg,
    #6b7aff -0.03%,
    #1430c6 120.49%
  ) !important;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: white;
  border: none;
}
.btnOrder span {
  font-size: 1rem;
}
.btnOrder_style {
  min-width: 187px;
  padding: 1rem;
  height: 40px;
  background: linear-gradient(
    139.36deg,
    #6b7aff -0.03%,
    #1430c6 120.49%
  ) !important;
  border-radius: 20px;
  display: flex;
  gap: 5px;
  align-items: center;
  color: white;
  border: none;
  justify-content: space-around;
}
.btnOrder_style span {
  font-size: 1rem;
}
.pet_avatar {
  width: 80%;
  max-height: 70%;
  height: 100%;
  scale: 1;
  transition: scale 0.3s ease;
}
.pet_avatar:hover {
  scale: 1.1;
}

.content_PetList {
  display: flex;
  justify-content: space-between;
}

.btnSearch {
  filter: drop-shadow(3px 9px 38px rgba(97, 97, 97, 0.3));
  display: flex;
  align-items: center;
  width: 50%;
}
.rectangle {
  width: 30px;
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.btnSearch button {
  background-color: #4470e2;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
}

.btnSearch button span {
  color: white;
}

.rowPointExchange {
  margin-top: 5px;
  display: flex;
  justify-content: center;
  gap: 5px;
}

.pointExchange {
  color: #0094ff;
}

.select {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: #45e5fc;
  border: 5px solid white;
}

.logo_poin {
  display: flex;
  align-items: center;
}

.btnSearchCol {
  display: flex;
  align-items: center;
  justify-content: end;
}

.content_Layout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.container_Layout_Left {
  z-index: 10;
}

.logo {
  border-radius: 0% 100% 11% 89% / 100% 0% 100% 0%;
  position: absolute;
  background: linear-gradient(
    274.68deg,
    #65bfcc 5.61%,
    #5bdaec 47.54%,
    #0cc1db 89.47%
  );
  width: 150px;
  height: 75px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  transform: translate(60%, -50%);
}

.content_listPet {
  width: 100%;
  height: 80%;
}
.content_listPet_item {
  width: 100%;
  height: 70vh;
  overflow-y: auto;
}
.content_listPet_item.content_listPet_item__short {
  height: 100% !important;
}

.content_listPet_item::-webkit-scrollbar {
  display: none;
}

.avatar {
  background-color: white;
  width: 120px;
  border: 2px solid rgba(20, 48, 198, 1);
  border-radius: 100%;
  position: relative;
  overflow: hidden;
  display: block;
  object-fit: fill;
}

.point_icon_status {
  color: white;
}

.status {
  position: relative;
  cursor: pointer;
  padding-bottom: 0.2rem;
}
.heart {
  position: absolute;
  min-width: 35px;
  min-height: 35px;
  top: 7px;
  left: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: white;
  object-fit: cover;
}
.heart_image {
  object-fit: cover;
  min-width: 55%;
  min-height: 55%;
}

.pet_name {
  font-size: 1.4rem;
}
.pet_message {
  position: absolute;
  display: flex;
  right: -1%;
  align-items: center;
  text-align: center;
  font-size: 0.85rem;
  top: 0;
  max-width: 50%;
  background: gray;
  line-height: normal;
  border-radius: 15px 15px 15px 0px;
  border: 3px solid;
  background: #fff;
  font-family: Salsa;
  font-style: normal;
  padding: 2%;
  font-weight: 400;
  opacity: 0;
  transition: opacity 0.5s ease;
}
.cardPet:hover .pet_message {
  opacity: 1;
}
@media only screen and (max-height: 800px) {
  .content_listPet_item {
    height: 60vh !important;
  }
}
@media only screen and (max-width: 1025px) {
  .avatar {
    top: -5px;
  }
  .btnOrder {
    height: 35px;
  }
  .btnOrder_style {
    height: 35px;
  }
  .select {
    width: 25px;
    height: 25px;
  }
  .content_listPet_item {
    height: 65vh;
  }
  .heart {
    min-width: 25px;
    min-height: 25px;
    top: 5px;
    left: 5px;
  }
  .heart_image {
    width: 50%;
    height: 50%;
  }
  .pet_message {
    font-size: 0.7rem;
  }
  .rectangle {
    width: calc(25px);
  }
}
/* custom antd */
