.container_loading{
    position: absolute; /* Full-screen */
    display: flex; /* Center the spinner horizontally and vertically */
    justify-content: center;
    align-items: center;
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    /* background-color: rgba(0,0,0,0.5); */
}

