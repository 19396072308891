.carousel .slide {
  min-width: 100%;
  margin: 0;
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-ok-button {
  border: 1px solid silver;
  color: black;
}

.carousel .thumbs {
  transition: all 0.15s ease-in;
  transform: unset !important;
  position: relative;
  list-style: none;
  white-space: nowrap;
  display: flex;
  flex-wrap: wrap;
}

.btn_Generate {
  border-radius: 40px;
  background-color: #5BA3B9 !important;
  color: white;
  width: 25%;
  margin-top: 4px;
}

.btn_Generate span {
  font-size: 13px;
}

.carousel {
  width: 30%;
}

.title_text {
  color: #65bfcc;
  font-size: 40px;
  line-height: 30px;
}

.lesson_name {
  color: #0a0a0a;
  text-align: center;
  font-size: 20px;
  font-family: Salsa;
  line-height: 30px;
}

.carousel .slide img {
  width: 100%;
  vertical-align: top;
  border: 0;
  border-radius: 20px;
}

.carousel .thumb {
  transition: border 0.15s ease-in;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  border: 3px solid #fff;
  border-radius: 20px;
}

.carousel img {
  width: 100%;
  display: inline-block;
  pointer-events: none;
  border-radius: 20px;
}

p.carousel-status {
  display: none;
}