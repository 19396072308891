::-webkit-scrollbar {
  width: 0;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

.title_text {
  color: #65bfcc !important;
  text-align: center;
  font-size: 40px;
  font-family: Salsa;
  line-height: 30px;
}

.lesson_name {
  color: #0a0a0a;
  text-align: center;
  font-size: 20px;
  font-family: Salsa;
  line-height: 30px;
}

.ant-table-header {
  border-radius: 0 !important;
  border: 2px solid #73d3d3;
}

.ant-table-thead {
  border-radius: 20px !important;
}

td.ant-table-cell.ant-table-cell-scrollbar {
  background: #73d3d3 !important;
}

.ant-table-wrapper .ant-table-thead > tr > td {
  background: #73d3d3 !important;
}
.ant-table-tbody .ant-table-cell {
  cursor: pointer;
}
.tableCourses-custom .ant-table table th,td {  font-size: 14px !important; text-align: center !important; }
.tableCourses-custom .ant-table-header {
  border: none;
}
.tableCourses-custom .ant-table-header >  table > .ant-table-thead  th.ant-table-cell {
  background-color: #FE998D !important;
}
.tableCourses-custom table > .ant-table-thead td.ant-table-cell.ant-table-row-expand-icon-cell {
  background-color: #FE998D !important;
}
.tableCourses-custom   .ant-table-content > table > .ant-table-thead th.ant-table-cell  {
  background-color: #FE998D ;
}
.tableChildCourses-custom td.ant-table-cell {
  background-color:transparent !important;
}
@media screen and (min-width:1350px) {
  .tableCourses-custom .ant-table table th,td {  font-size: 18px !important} 
}
