body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
  font-size: 20px;
  user-select: none;
  position: relative;
  width: 100%;
  color: black;
  flex-direction: column;
}

.item img {
  width: 100%;
  height: 100%;
}

.checkbox {
  position: absolute;
  top: 0;
  right: 10%;
}
.tableData{
  height: 100%;
}

.list {
  height: 90%;
  display: flex;
  justify-content: center;
}

.button {
  width: 100%;
  display: flex;
  justify-content: center;
}

.talbe_makeEbook {
  width: 100%;
  height: 90%;
}

.popup_modelArtWork img {
  width: 100%;
  border: 1px solid purple;
}

.icon_view {
  color: #6b7aff;
  width: 100%;
  display: flex;
  justify-content: center;
}

.icon_modify {
  color: #65bfcc;
  width: 100%;
  display: flex;
  justify-content: center;
}

.btn_save {
  border: 1px solid;
  color: black;
}
/*  */
/* #image_book {
  /* visibility: hidden etc. wont work */
  /* width: 70%;
  height:70%;
  opacity: 0;
  overflow: visible ;
  position: absolute;
} */
/* #image_book:focus + label {
  /* keyboard navigation */
  /* outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}  */
/* #image_book + label * {
  pointer-events: none;
} */ 
@media (min-width: 600px) {}