.container_chatbot {
  background-image: url("../../assets/background/BackgroundChat.png");
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 90% 100%;
  background-position: top;
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 1024px) {
  .container_chatbot {
    padding: 0 5px;
    width: 100%;
    height: 90%;
  }
}

@media only screen and (min-width: 1280px) {
}

@media only screen and (min-width: 1440px) {
}
