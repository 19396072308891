.container_popup {
  width: 100%;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 51%;
  right: 50%;
  transform: translate(50%, -50%);
}
.wapperContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wapperContent img {
  width: 20%;
}
.content_book {
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.content_book a {
  color: rgb(55, 55, 204);
  cursor: pointer;
}
.container_popup_product {
  width: 100vw;
  inset: 0;
  height: 100vh;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  background: linear-gradient(
    90deg,
    rgba(246, 247, 245, 0.773546918767507) 100%,
    rgba(255, 0, 1, 1) 100%,
    rgba(53, 121, 9, 0) 100%
  );
}

.container_popup_campus {
  position: absolute;
  width: 50%;
  height: 50%;
  top: 0%;
  left: 25%;
  z-index: 1;
}

.container_popup_addPage {
  position: absolute;
  width: 85%;
  height: 88%;
  border: 2px solid;
  background: white;
  top: -1%;
  left: 8%;
  border-radius: 20px;
}

.container_popup_product .container_popup {
  width: 80%;
  height: 100%;
  margin: auto;
}

.title_PopupUserList,
.title_PopupProductList {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 26px;
  color: #2a4365;
}

.popup_addPage {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  border-radius: 20px;
  position: relative;
}

.content_popup,
.content_popup_detail {
  padding: 10px 0;
  overflow: hidden;
  width: 75vw;
  height: 85vh;
  border: 3px solid;
  border-radius: 20px;
  position: relative;
  background: white;
  gap: 5px;
  overflow-y: auto;
}

.content_popup_detail {
  width: 70%;
  z-index: 1;
}

.content_popup_mascot {
  position: relative;
  padding: 2.5%;
  border-radius: 3px;
  max-width: 90vw;
  min-height: 80vh;
  max-height: 100vh;
  display: flex;
  justify-content: center;
  border-radius: 20px;
  background: white;
}

.content_popup_enrolment {
  background: white;
  border: 2px solid;
  border-radius: 17px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title_popup_campus {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 26px;
  color: #2a4365;
}

.btn_close_popup {
  width: 50px;
  height: 35px;
  position: absolute;
  z-index: 10;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.content_popup_campus {
  text-align: start;
  display: flex;
  flex-direction: column;
}

.left_popup_addPage {
  width: 55%;
  height: 100%;
}

.right_popup_addPage {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.page_status_head,
.page_status_second {
  width: 80%;
  height: 30%;
  display: flex;
  flex-direction: column;
  background: #edf2f7;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
}

.page_status_second {
  height: 60%;
}

.form_pageStatus {
  width: 100%;
  display: grid;
  grid-auto-rows: auto;
  justify-content: center;
  align-items: center;
  margin: unset;
  overflow-y: auto;
}

.form_pageStatus .btn_addPage,
.btn_View {
  background-image: linear-gradient(
    139.36deg,
    #6b7aff -0.03%,
    #1430c6 120.49%
  ) !important;
  border-radius: 8px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn_addPage {
  border-radius: 8px;
  background-image: linear-gradient(
    154deg,
    #6b7aff 0%,
    #1430c6 100%
  ) !important;
}

.btn_View.icon {
  width: 20px;
  height: 20px;
}

.form_pageStatus .title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2a4365;
}

.bgMascot {
  max-width: 40%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 15px;
  display: flex;
  align-items: center;
}
.infoMascot {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.detailMascot {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bgNameMascot {
  position: relative;
}
.bgNameMascot h3 {
  font-size: 20px;
  position: absolute;
  color: white;
  top: 50%;
  right: 50%;
  transform: translate(50%, -13%);
  text-transform: uppercase;
}
.levelMascot {
  margin-top: 4%;
  text-align: center;
  display: inline-block;
  min-width: 30px;
  max-width: calc(100% - 10%);
  border-radius: 35px;
  background: #fff;
  box-shadow: 0px 23px 29.323558807373047px 0px rgba(147, 147, 147, 0.35),
    0px -2px 3px 0px #c0c0c0 inset;
  padding: 3px 23px;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  color: #65bfcc;
}
.imgMascot {
  width: 60%;
}
/* bg mascot */
.styleBtn {
  background-image: linear-gradient(
    93.04deg,
    #a2d8de -42.16%,
    #65bfcc 21.8%,
    #73d3d3 92.64%,
    #65bfcc 146.76%
  ) !important;
  outline: none;
  border: none;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  border-radius: 20px;
  padding: 20px 30px;
}
.exchange span {
  font-size: 20px;
}
.owned {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 30px;
  border-radius: 20px;
  background-color: #bc0000;
}
.desc {
  width: calc(70% - 40px);
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  gap: 5%;
}
.title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
}
.petname {
  color: #65bfcc;
  font-size: 3.125rem;
}
.wapperPoint {
  display: flex;
  align-items: center;
}
.wapperPoint img {
  width: 25px;
  height: 25px;
  margin-right: 5px;
}
.point {
  color: #bc0000;
  font-size: 1.7rem;
}

.petStory {
  letter-spacing: 0.5px;
}

.popupTitle {
  color: #2a4365;
  font-family: "Roboto";
  font-weight: 900;
}

.inquiryInformation,
.answerInformation,
.answerContents {
  font-size: 16px;
  color: #2a4365;
  font-weight: 700;
  font-family: "Roboto";
  margin: 20px 0px;
}

.ant-form-item-label {
  text-align: start;
}

@media only screen and (max-width: 375px) {
  .ModalAnswer {
    width: 50%;
  }
}

@media only screen and (min-width: 1280px) {
  .content_book_backgroud {
    width: 80%;
    height: 40%;
  }

  .content_popup_detail {
    height: 75%;
    width: 80%;
  }

  .content_popup {
    width: 50vw;
    height: 65vh;
  }
}

@media only screen and (min-width: 1500px) {
  .content_popup_mascot {
    width: 60vw;
  }
  .imgMascot {
    width: 80%;
  }
  .petStory {
    font-size: 18px;
    letter-spacing: 1.5px;
    line-height: 2;
  }
  .exchange span {
    font-size: 30px;
  }
}

@media only screen and (min-width: 1920px) {
}
.customMenuItem {
  background-color: red;
}
