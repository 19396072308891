.container_inputChat {
  min-height: 10%;
  height: 70px;
  width: 100%;
  gap: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -158px;
}

.container_inputChat textarea {
  padding-right: 80px;
}

.btnSend {
  width: 90%;
  height: 90%;
}
.microphone {
  /* width: 35px;
  height: 35px;
  background-color: transparent;
  border: none; */
}
.container_inputChat .btnSend {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  background: #37cdbc;
  border-radius: 12px;
  border: none;
  transition: 0.2s;
}

.container_inputChat .btnSend:hover {
  background: #2aa799;
}

/* .button {
  width: 90%;
  height: 90%;
  position: absolute;
  top: 15%;
  left: 80%;
} */

.inputForm {
  display: flex;
  justify-content: center;
  align-items: start;
  height: auto;
  width: 94%;
  position: relative;
  white-space: pre;
  word-break: break-word;
  align-items: center;
}

.inputForm .inputChat {
  height: 44px;
  color: #000;
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 2px solid #e2e2e2;
  border-radius: 8px;
  padding: 10px 24px;
  resize: none;
  transition: 0.2s;
}

.inputForm .inputChat:hover {
  outline: none;
  border: 2px solid#2AA799;
}

.inputForm .inputChat:focus {
  outline: none;
  border: 2px solid#2AA799;
}

@media only screen and (max-width: 900px) {
  .container_inputChat {
    margin-bottom: 0px;
  }
}

@media only screen and (width: 1024px) {
  .container_inputChat {
    margin-bottom: -114px;
  }
}
@media screen and (min-width: 1300px) {
  .inputChat {
    height: 100px;
  }
}
