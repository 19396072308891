.ant-input-group-addon{
    background-color: #66bfcc;;
}
#makeBookCheck:hover > label{
    color: white
}
#makeBookCheck > label{
    /* width:170px; */
    width: 15vw;
    font-size: 1vw;
    text-align: center;

}
#makeBookCheck label span{
    position: relative;
    top:0.1vw
}

#makeBookCheck label{
    position: relative;
    top:0.3vw;
    background-color: #a2d9de;
}

#makeBookCheck .ant-radio-button-wrapper-checked{
    background-color: #fe998d !important;
}