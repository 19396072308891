td.ant-table-cell {
  padding: 10px 10px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  word-break: break-word !important;
}

.ant-table-wrapper .ant-table-thead > tr > td {
  padding: 10px 10px !important;
}

.ant-table-wrapper .ant-table-thead > tr > th {
  padding: 10px 10px !important;
}

td.ant-table-cell {
  font-size: 9px;
}


@media screen and (min-width: 1280px) {
  td.ant-table-cell {
    font-size: 12px;
  }
}