.navbar {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 123px;
  background: white;
  border-radius: 0px 0px 20px 20px;
}

.navbar a {
  text-decoration: unset;
  color: rgba(0, 0, 0, 0.4);
  padding: 0px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;

  @media screen and (min-width: 1024px) {
    padding: 0px 20px;
  }
}

.navbar .narbar_content {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar .navbar_btnlogin {
  position: relative;
  width: 9%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar .element_menu:hover {
  background: linear-gradient(
    93.04deg,
    #a2d8de -42.16%,
    #65bfcc 21.8%,
    #65bfcc 92.64%,
    #65bfcc 146.76%
  );
  box-shadow: 0px 20px 32px rgba(101, 191, 204, 0.3),
    inset 0px -3px 14px rgba(0, 114, 118, 0.3);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: whitesmoke;
}

.navbar .active {
  color: white;
  background: #3cb0c1;
  border-radius: 40px;
}

.logo {
  width: 100px;
  height: 50px;
}

.avatar_user {
  border: solid 2px blue;
  border-radius: 50px;
  width: 40%;
  height: 70%;
  cursor: pointer;
}

.avatar_user img {
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 1024px) {
  .navbar .narbar_content {
    width: 55%;
  }
  .navbar .element_menu:hover {
    height: 25px;
  }

  .navbar .active {
    color: white;
    background: #65bfcc;
    height: 30px;
  }
}

@media screen and (min-width: 1280px) {
  .logo {
    width: 150px;
    height: 60px;
  }

  .navbar a {
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    padding: 0px 10px;
  }
  .navbar .element_menu:hover {
    height: 50px;
  }
  .navbar .active {
    height: 50px;
  }
}

@media screen and (min-width: 1920px) {
  .logo {
    width: 169px;
    height: 95px;
  }

  .navbar a {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    padding: 0px 20px;
  }

  .avatar_user {
    width: 50%;
  }
}
