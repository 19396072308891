.container_form {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 35px;
}
.form {
  height: 80%;
  width: 40%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  backdrop-filter: blur(100px);
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
  background: rgba(255, 254, 254, 0.7);
  -webkit-backdrop-filter: blur(42px);
  backdrop-filter: blur(42px);
  border-radius: 30px;
  position: relative;
}

.content_form {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content_box_form {
  width: 70%;
  margin: auto;
}

.content_form .inputForm {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: #fdfdfd;
  border: 1px solid #d1ebec;
  box-shadow: 3px 9px 38px rgba(97, 97, 97, 0.1);
  border-radius: 15px;
}

.content_form .labelInput label {
  font-size: 16px;
  line-height: 135.5%;
  color: #386386;
}

.labelInput {
  margin: 15px 0px;
}

.btnForm {
  font-family: "Salsa";
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  background-color: #5ba3b9 !important;
  box-shadow: 0px 23px 29.3236px rgba(69, 90, 227, 0.2),
    inset 0px -6px 14px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
}

.btnFormTalkSam {
  margin: auto 0px;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  width: 100%;
  height: 35px;
  background-color: #fe998d !important;
  box-shadow: 0px 23px 29.3236px rgba(69, 90, 227, 0.2),
    inset 0px -6px 14px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  display: flex;
  justify-content: center;
}
.checkBoxGroup_style {
  background-color: #d1ebec;
  border-radius: 15px;
  padding: 10px;
}
.formJoin {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  margin-top: 20px;
}
.btnNext {
  padding: 10px 4px;
  color: white;
  background-color: #fe998d !important;
  size: 18px;
  border-radius: 10px;
}
.btnNext_disable {
  padding: 10px 4px;
  color: white;
  background-image: linear-gradient(139.36deg, silver) !important;
  size: 18px;
  border-radius: 10px;
}
.btnFormJoin {
  width: 50%;
  color: white;
  background-color: #fe998d !important;
  size: 18px;
  border-radius: 10px;
}
.btnFormJoin:hover {
  border: none;
}

.btnFormJoin:disabled {
  background-color: silver !important;
  width: 50%;
  border-radius: 10px;
  color: white;
}
.btnFormJoin:active {
  background-color: silver !important;
  width: 50%;
  border-radius: 10px;
  color: white;
}
.checkeboxColor span {
  color: #707b7e;
}
.btnFormJoin div {
  width: 100%;
}
.btn_next {
  font-family: "Salsa";
  font-style: normal;
  font-weight: 400;
  max-width: 170px;
  display: block;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  width: 100%;
  margin: auto;
  background-color: #5ba3b9 !important;
  box-shadow: 0px 23px 29.3236px rgba(69, 90, 227, 0.2),
    inset 0px -6px 14px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
}
.diviver {
  margin: 10px 0px;
}

.btn_back {
  position: absolute;
  top: 10%;
  left: 5%;
  font-size: 30px;
  color: #386386;
  margin-top: 20px;
}

.form .boxHeader {
  display: flex;
  gap: 5px;
  width: 70%;
  padding-top: 20px;
}

.lineHeader {
  margin: auto;
  width: 40%;
  border: 1px solid #386386;
}

.form .boxHeader .textHeader {
  font-family: "Salsa";
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  color: #386386;
}
.text {
  width: 122px;
  color: #386386;
  font-size: 40px;
  text-align: center;
}

/* style input email focus*/
.form_input {
  transition: 0.25s ease;
  padding: 20px;
  width: 100%;
  outline: none;
  border: 0;
  height: 50px;
}
.form_input:focus {
  border: 1px solid #6b7aff;
}
.form_Login {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form_field {
  position: relative;
}
.form_label {
  position: absolute;

  left: 20px;
  user-select: none;
  color: black;
  pointer-events: none;
  z-index: 10;
  top: 20%;
  transform: translateY(-50%);
  transition: 0.25s ease;
}
.form_input:not(:placeholder-shown) + .form_label,
.form_input:focus + .form_label {
  /* transform: translateY(-50%);
  top: 0;
  display: inline-block;
  background-color: #f7fdff;
  padding: 0 10px;
  color: #6b7aff; */
}
/* style input password focus*/
.form_field_password {
  position: relative;
}
.form_input_password {
  width: 100%;
  height: 50px;
  padding: 20px;
  transition: 0.25s ease;
  border: 0;
}
.form_label_password {
  position: absolute;

  left: 20px;
  user-select: none;
  color: black;
  pointer-events: none;
  z-index: 10;
  top: 20%;
  transform: translateY(-50%);
  transition: 0.25s ease;
}

.form_input_password:focus {
  border: 1px solid #6b7aff;
}
.form_input_password:not(:placeholder-shown) + .form_label_password,
.form_input_password:focus + .form_label_password {
  /* transform: translateY(-50%);
  display: inline-block;
  top: 0;
  background-color: #f7fdff;
  padding: 0 10px;
  color: #6b7aff; */
}

.left_icon,
.right_icon {
  position: absolute;
}

.left_icon {
  bottom: 2%;
  left: 15%;
  width: 20%;
}

.right_icon {
  right: 14%;
  top: 10%;
  width: 20%;
}

@media only screen and (min-width: 1024px) {
  .form {
    height: 90%;
  }
  .form .boxHeader .textHeader {
    font-size: 30px;
  }
}
@media only screen and (min-width: 1280px) {
  .form {
    height: 80%;
  }
}
@media only screen and (min-width: 1440px) {
  .form {
    height: 90%;
  }
  .labelInput {
    margin: 14px 0px;
  }
}
@media only screen and (min-width: 1920px) {
  .form {
    height: 70%;
  }
  .labelInput {
    margin: 20px 0px;
  }
}
