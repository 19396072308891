.container,
.container_brainstorming {
  width: 60%;
  height: 35%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.container3 {
  width: 0%;
}

.content_title,
.content_title_lesson {
}

.content_title_lesson {
  display: flex;
  flex-direction: column;
}

h1.content_title,
h1.content_title_lesson {
  font-size: 20px;
}

.content_star_rating {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  padding: 2px 0;
}

.container_start {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.content_topic,
.content_topic_desktop {
  background: url("../../assets/icon_svg/background_topic.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.content_topic_branstorming {
  background: url("../../assets/icon_svg/background_red.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  flex-direction: column;
  color: white;
}

.content_book_help {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px;
  gap: 10px;
  width: 150px;
  height: 30px;
  background: #ffffff;
  box-shadow: 0px 23px 29.3236px rgba(69, 90, 227, 0.2),
    inset 0px -6px 14px rgba(0, 0, 0, 0.1);
  border-radius: 15px 15px 15px 0px;
}

.img_topic,
.img_topic_desktop,
.img_topic_storming {
  position: absolute;
  top: -2%;
  left: -26%;
  display: none;
}

.content_topic p,
.content_topic_desktop p {
  font-family: "Salsa";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
}

.popup_details {
  z-index: 1;
  background: white;
  border: 2px solid purple;
  border-radius: 25px;
  position: absolute;
  width: 90%;
  height: 35vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.content_final_evaluation,
.content_error {
  width: 80%;
}

.content_error {
  display: flex;
  flex-direction: column;
}

.btn_close_popup {
  position: absolute;
  top: 3%;
  right: 3%;
  width: 35px;
  height: 35px;
  color: purple;
}

.img_popup_help {
  z-index: 1;
  position: absolute;
  top: 60%;
  left: 4%;
}

/* .content_title p {
  color: #ffffff;
} */

.container_Start {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
}
.content_leftStart {
  width: 40%;
  text-align: start;
  margin-right: 1px;
  margin-top: 6px;
}

.content_rightStart {
  width: calc(60% - 1px);
  display: flex;
  align-items: center;
}

.rightStar {
  font-size: 12px;

  @media screen and (min-width: 1440px) {
    font-size: 20px;
  }
}

ul.ant-rate.css-dev-only-do-not-override-12jzuas.ant-rate-disabled {
  font-size: 15px !important;
}

.content_rightStart ul {
  white-space: nowrap;
}

@media screen and (min-width: 1024px) {
  .content_topic_desktop {
    background: url("../../assets/icon_svg/backgroundTopic.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    width: 26%;
    left: 40%;
    z-index: 1;
    top: 16%;
    flex-direction: column;
    align-items: center;
  }

  .content_topic p,
  .content_topic_desktop p {
    font-weight: 400;
    font-size: 15px;
    line-height: 10px;
    justify-content: center;
  }

  .img_popup_help {
    top: 80%;
    left: 4%;
    width: 100%;
  }

  .img_topic_desktop {
    bottom: 55%;
    left: 0%;
    display: block;
    width: 13%;
    position: absolute;
  }
  .content_final_evaluation {
    height: 100%;
    width: 100%;
    min-height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: start;
    padding: 3px 1px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(42px);
    border-radius: 10px;
  }
}

@media screen and (min-width: 1280px) {
  .img_topic,
  .img_topic_storming {
    top: -40%;
    left: 0%;
    display: block;
    width: 23%;
  }
  .content_star_rating {
    width: 100%;
  }

  .img_topic_desktop {
    bottom: 55%;
    left: 0%;
    width: 18%;
  }

  .content_writingCenter {
    display: flex;
    flex-direction: row;
  }

  .container {
    height: 90%;
    gap: 60px;
    width: 30%;
  }

  h1.content_title,
  h1.content_title_lesson {
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 28px;
  }

  h1.content_title_lesson {
    font-style: normal;
    font-weight: 400;
    font-size: 43px;
    line-height: 4px;

    color: #ffffff;
  }

  .content_topic {
    justify-content: center;
    align-items: center;
    word-break: break-word;
  }

  .content_topic p,
  .content_topic_desktop p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 12px;
    justify-content: center;
  }

  .content_topic_desktop {
    width: 26%;
    left: 40%;
    top: 12%;
  }

  .popup_details {
    height: 50vh;
    width: 50%;
    left: 25%;
    top: 9%;
    z-index: 10;
  }

  .icon_C {
    position: absolute;
    top: 10%;
    left: 30%;
  }

  .icon_C_stepFinal {
    position: absolute;
    top: 14%;
    left: 22%;
    width: 6%;
  }

  h1.content_final_evaluation {
    text-align: start;
  }

  .content_error {
    height: 10%;
    justify-content: space-between;
  }

  .content_star_rating {
    width: 100%;
    line-height: unset;
  }
}

.content_feedbackEvaluation {
  background-image: linear-gradient(
    0deg,
    #73d3d3 0%,
    rgba(255, 255, 255, 0) 100%
  );
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  height: 40%;
  width: 100%;
  position: relative;
}

.icon_feedback {
  left: 90%;
  width: 7%;
  bottom: 3%;
  position: absolute;
}

.content_feedbackEvaluation p {
  height: 85%;
  width: 60%;
  overflow-y: auto;
  overflow-x: hidden;
  line-height: 1.1;
  font-size: 13px;
}
.btn_mistake {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 5px;
  background-color: #bc0000 !important;
  color: white;
  border: none;
}
.btn_approvement {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 5px;
  background-color: var(--color-3, #00a3bb) !important;
  border: none;
  color: white;
}

@media screen and (min-width: 1024px) {
  .container {
    height: 50%;
    width: 16%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px 0px;
    position: absolute;
    z-index: 1;
    left: 1%;
    top: 8%;
  }

  .content_feedbackEvaluation {
    height: 36%;
  }

  .container_brainstorming {
    height: 7%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: start;
  }

  .content_topic {
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .content_topic_branstorming {
    z-index: 10;
    width: 44%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .box_title {
    font-size: 12px;
    width: 80%;
    text-align: center;
    line-height: 10px;
    padding-bottom: 4px;
  }
  .box_title_topic {
    font-size: 12px;
    width: 80%;
    text-align: center;
    line-height: 10px;
    padding-bottom: 4px;
    color: white;
  }

  .img_topic {
    top: -14%;
    left: -3%;
    display: block;
    width: 15%;
  }

  .img_topic_storming {
    top: -19%;
    left: 0%;
    display: block;
    width: 20%;
  }

  h1.content_title,
  h1.content_title_lesson {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #2d45d3;
    padding-top: 4px;
  }
  h1.content_title_topic {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    padding-top: 4px;
    color: white;
  }

  h1.content_title_evaluation {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 10px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #2d45d3;
    padding: 8px 0px;
  }

  .icon_feedbackEvaluation {
    width: 20%;
    position: absolute;
    left: -3%;
    top: 20%;
  }
}

@media screen and (min-width: 1280px) {
  h1.content_title_evaluation {
    font-size: 20px;
    padding: 15px 0px;
    margin-top: 20px;
  }
  .container {
    gap: 20px;
  }
  .content_topic_branstorming {
    width: 40%;
  }

  .box_title {
    font-size: 15px;
    width: 60%;
    text-align: center;
    line-height: 10px;
    padding-bottom: 8px;
  }
  .box_title_topic {
    font-size: 15px;
    width: 90%;
    text-align: center;
    line-height: 16px;
    padding-bottom: 8px;
  }
  .content_feedbackEvaluation p {
    line-height: 15px;
    font-size: 16px;
  }
  .feedbackEvaluation p {
    font-size: 18px;
  }
  .container_Start {
    font-size: 14px;
  }
}

@media screen and (min-width: 1440px) {
  .content_feedbackEvaluation p {
    /* font-size: ; */
    background-color: red;
  }
}

@media screen and (min-width: 1920px) {
  .box_title {
    font-size: 20px;
    line-height: 25px;
  }
  .box_title_topic {
    font-size: 20px;
    line-height: 25px;
  }

  h1.content_title,
  h1.content_title_lesson {
    font-size: 15px;
  }

  h1.content_title {
    padding-top: 5px;
    font-size: 25px;
  }

  .content_topic p,
  .content_topic_desktop p {
    font-size: 20px;
    line-height: 24px;
  }

  .img_topic {
    width: 18%;
  }

  .img_topic_desktop {
    width: 15%;
  }

  .content_feedbackEvaluation {
    height: 65%;
  }

  .container_brainstorming {
    height: 10%;
  }

  h1.content_title_topic {
    font-size: 20px;
    padding-top: 5px;
  }
  .container_Start {
    font-size: 16px;
  }
}
