.mypage {
  background-image: url("../../../../assets/background/backgound_mypage.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.content_mypage {
  width: 90%;
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: start;
}

.table_Course {
  width: 80%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.table_Course::-webkit-scrollbar {

  width: 5px !important;
}

.narbar_mypage {
  display: flex;
}

.btn_mypage {
  width: 200px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px blue;
  border-radius: 20px;
}

.mypage .btn_mypage:hover {
  background: blue;
  display: flex;
  justify-content: center;
  align-items: center;
  color: whitesmoke;
  border-radius: 10px;
  height: 50px;
}

.custom {
  background: red;
}

.ant-tabs-tab {
  background-color: red;
  color: white;
}

.logo_studyRoom {
  background: url("../../../../assets/icon_svg/logo_Studyroom.png");
  background-size: 100% 100%;
  width: 250px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: justify;
  position: absolute;
  top: 5%;
  left: 5%;
}

.logo_studyRoom p {
  position: absolute;
  left: 40%;
  font-family: "Salsa";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 50px;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.btn_home {
  position: absolute;
  right: 5%;
  top: 3%;
  width: 8%;
}

.logo_ebook {
  z-index: 1;
  width: 12%;
  position: absolute;
  top: 2%;
  left: 10%;
}
@media only screen and (min-width: 1024px) {
  .logo_studyRoom {
    width: 180px;
    height: 60px;
  }
  .table_Course {
    width: 90%;
    height: 100%;
    overflow: scroll;
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.85);
  }
}
@media only screen and (min-width: 1280px) {
  .table_Course {
    width: 90%;
  }
}
