.boxBtnStableDiff {
  display: flex;
  flex-direction: row;
  width: 98%;
  justify-content: space-between;
  position: absolute;
  top: 2%;
}

.btnStep {
  border-radius: 40px;
  color: #001cff;
  border: 1px solid #333;
}

.boxPrompt {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80%;
  height: 90%;
  background: #ffffff;
  border-radius: 40px;
  border: 1px solid #333;
  overflow: auto;
}

.boxLeftPrompt {
  width: 100%;
  height: 100%;
}

.boxLeftPrompt div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.boxRightPrompt {
  width: 90%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container_dragable {
  flex-direction: column;
  width: 80%;
  height: 82vh;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
  -webkit-backdrop-filter: blur(42px);
  backdrop-filter: blur(42px);
  border-radius: 40px;
  border: 3px solid #fff;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.title_boxleft {
  font-family: "Salsa";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #0094ff;
}

.title_Coures {
  font-family: "Salsa";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: white;
}

.content_leftDragable {
  width: 90%;
  background: linear-gradient(
    to bottom right,
    #a2d8de,
    #65bfcc,
    #73d3d3,
    #65bfcc
  );
  height: 25%;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
}

.btn_choseTemplates {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 60%;
  height: 5%;

  border-radius: 20px;
}

.content_templates {
  width: 45%;
  height: 50%;
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 15px;
  justify-items: center;
}

.item_template {
  width: 60%;
}

.img_template {
  width: 100%;
}

.content_ebook {
  width: 100%;
  height: 60%;
  text-align: center;
}

h3.content_ebook {
  color: red;
}

.ebook_save {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ebook_square,
.ebook_rectangle {
  height: 100%;
  background: white;
}

.course_name {
  color: #65bfcc;
  text-align: center;
  font-size: 40px;
  font-family: Salsa;
  line-height: 30px;
  margin-top: 5px;
}

.lesson_name {
  /* color: #0a0a0a; */
  color: #65bfcc;
  text-align: center;
  font-size: 20px;
  font-family: Salsa;
  line-height: 30px;
}

.ebook_square {
  width: 92%;
}

.ebook_rectangle {
  width: 60%;
}

.content_stickerPet {
  width: 90%;
  height: 10%;
  overflow: hidden;
  text-align: center;
  background: white;
  border-radius: 60px;
  color: rgb(45, 69, 211);
}

.list_stickerPet {
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  height: 100%;
}

.list_stickerPet img {
  width: 8%;
}

.btn_square,
.btn_rectangle {
  width: 50%;
  height: 100%;
  text-align: center;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
}

.content_boxPrompt {
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.boxPrompt {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 90%;
  background: #ffffff;
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(42px);
}

.box_keyPrompt {
  border: 1px solid #0094ff;
  border-radius: 15px;
  font-size: 14px;
  background: white;
  position: relative;
}
.btn_Returns,
.btn_Next,
.btn_Dashboard,
.btn_Return,
.btn_Back {
  border: none !important;
  border-radius: 20px !important;
}

.btn_Returns {
  background-color: #5ba3b9 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -322%;
}

.tittle {
}

.btn_Next {
  font-size: 16px;
}

.btn_Next {
  background-color: #fe998d !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn_Back {
  background-image: linear-gradient(
    #a2d8de,
    #65bfcc,
    #73d3d3,
    #65bfcc
  ) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn_Return {
  background-color: #5ba3b9 !important;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn_Dashboard {
  background-color: #fe998d !important;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.course_name {
  font-size: 24px;
}

.lesson_name {
  font-size: 16px;
}

.topic {
  font-size: 14px;
}

.img_template img {
  width: 70%;
  border-radius: 20px;
}

.group_button {
  display: flex;
  flex-direction: row;
  width: 98%;
  margin: 0px auto;
  justify-content: space-between;
  position: absolute;
  top: 2%;
}

.btn_Download {
  background-color: #fff !important;
  color: blue;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn_Next span,
.btn_Return span,
.btn_Dashboard span,
.btn_Download span,
.btn_Back span {
  font-size: 13px;
}

@media screen and (min-width: 1024px) {
  .content_boxPrompt {
    width: 100%;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title_boxleft {
    font-size: 14px;
  }

  .boxPrompt {
    width: 80%;
    height: 86vh;
    flex-direction: column;
  }

  .boxRightPrompt {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .boxLeftPrompt {
    width: 50%;
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .icon_loadDing {
    position: absolute;
    left: 71%;
    top: 11%;
  }
}

@media screen and (min-width: 1280px) {
  .boxRightPrompt {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .boxLeftPrompt {
    width: 50%;
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
}

.content_boxRightPrompt {
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  border-radius: 35px;
}

.content_boxRightPromptRow {
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 35px;
}

.btn_Generate {
  border-radius: 40px;
  background-image: rgb(20, 48, 198) !important;
}

.container_dragable {
  flex-direction: row;
  width: 80%;
  height: 85vh;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(42px);
  border-radius: 40px;
  border: 3px solid #fff;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: start;
  padding-top: 10px;
}

.content_ebook {
  width: 50%;
  height: 100%;
  text-align: center;
}

.content_leftDragable {
  width: 20%;
  /* background: linear-gradient(to bottom right,
      #a2d8de,
      #65bfcc,
      #73d3d3,
      #65bfcc); */
  background: #fe998d;
  height: 65%;
  border-radius: 19px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 25px;
}

.content_templates {
  width: 85%;
  height: 55%;
  display: grid;
  grid-template-columns: auto auto;
  gap: 15px;
}

.list_stickerPet img {
  width: 50%;
}

.content_stickerPet {
  width: 20%;
  height: 90%;
  text-align: center;
  background: white;
  border-radius: 19px;
}

.item_template {
  width: 100%;
}

.ebook_square,
.ebook_rectangle {
  height: 98%;
  background: white;
  overflow-y: scroll;
  overflow-x: hidden;
}

.ebook_square::-webkit-scrollbar {
  width: 5px !important;
}

@media screen and (min-width: 1024px) {
  .img_splitter {
    width: 70%;
    border-radius: 10px;
  }

  .content_leftDragable {
    height: 70%;
  }

  .course_name {
    font-size: 16px;
  }

  .lesson_name {
    font-size: 26px;
    padding-top: 3px;
  }

  .topic {
    font-size: 12px;
  }
}

@media screen and (min-width: 1280px) {
  .img_splitter {
    width: 90%;
  }

  .course_name {
    font-size: 24px;
  }

  .topic {
    font-size: 14px;
  }
}

@media screen and (min-width: 1440px) {
  .item_template {
    width: 85%;
  }

  .img_splitter {
    width: 85%;
  }
}
