.container_Infor {
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: space-evenly;
}

.container_listCampus {
  width: 100%;
  height: 70vh;
  text-align: center;
}

.title_listCampus {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 26px;
  color: #2a4365;
  text-align: center;
}

.btn_addPage {
  border-radius: 8px;
  background-image: linear-gradient(
    154deg,
    #6b7aff 0%,
    #1430c6 100%
  ) !important;
  color: white;
}
.filter_Date {
  height: 20%;
}

.list_tableCampus {
  width: 85%;
  overflow: hidden;
}

.list_admin {
  width: 100%;
  height: 60vh;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  overflow-y: auto;
  overflow-x: hidden;
}

.checkBox {
  width: 100%;
  grid-template-columns: auto auto auto;
  display: grid;
}

.checkBox p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 26px;
  color: #2a4365;
}

.user_infor {
  width: 40%;
}

.admin_pms,
.user_infor {
  width: 100%;
  height: 100%;
  background: rgba(237, 242, 247, 0.5);
  border-radius: 15px;
  overflow-y: auto;
  overflow-x: hidden;
}

.user_infor_title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 26px;
  color: #2a4365;
  text-align: center;
}

.user_infor img {
  width: 100%;
  height: 100%;
  border: 2px solid purple;
  border-radius: 20px;
}

.avatar_user {
  display: grid;
  grid-template-rows: auto auto;
  justify-items: center;
  align-items: center;
  object-fit: cover;
  gap: 15px;
}

.content_approval {
  width: 100%;
  height: 95%;
  display: grid;
  grid-auto-rows: auto;
}

.form_approval {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table_approval {
  margin: 10px 0;
  overflow-x: scroll;
  overflow-y: scroll;
}

.list_user {
  width: 60%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  background: #edf2f7;
  border-radius: 15px;
  gap: 10px;
  padding: 10px;
  overflow-y: auto;
}

/* Coming Soon */
.comingSon {
  /* background: #f1e5e5; */
  border-radius: 30px;
  padding: 196px;
  width: 100%;
}

.comingSon {
  font-size: 1.4rem;
  color: #eb2222;
}

.img_1,
.img_2 {
  position: absolute;
  z-index: 1;
}
.img_1 {
  bottom: -10%;
  right: -8%;
}
.img_2 {
  top: -15%;
  left: -10%;
}

.btn_inquiryManager {
  width: 90%;
  height: 90%;
  border-radius: 8px;
  background-image: linear-gradient(
    154deg,
    #6b7aff 0%,
    #1430c6 100%
  ) !important;
  color: white;
  margin-left: -24px;
}

@media screen and (min-width: 1280px) {
  .user_infor_title {
    font-size: 20px;
  }

  .user_infor img {
    width: 70%;
  }

  .list_tableCampus {
    width: 100%;
    height: 100%;
  }
  .admin_pms {
    height: 70vh;
  }
}
@media screen and (min-width: 1440px) {
}

@media screen and (min-width: 1920px) {
}
