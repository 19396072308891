/* Optional: Customize the slide content */
/* .swiper-co */
.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  /* background-color: #e0e0e0; */
  width: 100px;
}

.swiper-container {
  width: 13%;
}

.petIcon {
  width: 100%;
  border-radius: 50%;
  background: linear-gradient(#00E5FF 0 0) no-repeat;
}

.content_petIcon {
  width: 50%;
}

.content_petIcon img {
}

@media screen and (width: 1024px) {
  .swiper-container {
    width: 12.5%;
  }
}
