.container_myPage {
  background-image: url("../../../../assets/background/background_login.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.container_userPage {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container_stable {
  background: url("../../../../assets/background/background_dra.png");
  background-size: 101% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    height: 75vh;
  }
}

.contents {
  width: 75%;
  height: 78vh;
  display: flex;
  padding: 20px 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(42px);
  border: 5px solid white;
  border-radius: 30px;
}

.content_button {
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: space-between;
  z-index: 1;
  position: relative;
}

.content_writingCenter,
.content_writingCenter_BrainStorming {
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 12px;
  z-index: 0;
  justify-content: flex-start;
  align-items: start;
}

.content_writingCenter_BrainStorming {
  align-items: center;
}

.group_btn {
  height: 40px;
  display: flex;
  justify-items: center;
  justify-content: center;
  gap: 30px;
  margin-left: 0px;
  margin-bottom: 20px;
}

.sub_group_btn {
  position: relative;
}

.btnAvatar {
  position: absolute;
  top: -60%;
  left: -35%;
  width: 90px;
}

.button_writing_center,
.button_writing_center_nextSuggestion {
  background: linear-gradient(139.36deg, #6b7aff -0.03%, #1430c6 120.49%);
  border-radius: 24px;
  font-family: "Salsa";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 30px;
  border: none;
  color: #ffffff;
  height: 35px;
  width: 140px;
  cursor: pointer;
}

@media screen and (min-width: 1024px) {
  .content_button {
    width: 98%;
    height: 5%;
    display: flex;
    justify-content: space-between;
    z-index: 1;
    position: absolute;
    top: -7%;
  }
}

.content_writingCenter {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.contents {
  width: 85%;
  height: 70vh;
}

.button_writing_center,
.button_writing_center_nextSuggestion {
  background: #5ba3b9;
  border-radius: 24px;
  font-family: "Salsa";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 1px;
  border: none;
  color: #ffffff;
  height: 27px;
  width: 120px;
  cursor: pointer;
}

.content_feedbackEvaluation {
  background: linear-gradient(
    rgb(255 255 255 / 1%),
    rgb(115 211 211 / 15%),
    #73d3d3
  );
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 1;
  height: 50%;
  width: 16%;
  position: absolute;
  right: 1%;
  bottom: 30%;
  border-radius: 10px;
}

.content_feedbackEvaluation p {
  overflow: scroll;
  position: absolute;
  right: 10%;
  top: 5%;
  height: 85%;
  width: 65%;
  overflow-y: scroll;
  overflow-x: hidden;
  line-height: 1.3;
  font-size: 12px;
}
.content_feedbackEvaluation p::-webkit-scrollbar {
  /* display:unset !important; */
  width: 5px !important;
}

.icon_feedbackEvaluation {
  width: 23%;
  position: absolute;
  left: -3%;
  bottom: 2%;
}

.icon_feedback {
  left: 82%;
  width: 12%;
  bottom: 3%;
  position: absolute;
}

h1 .content_title {
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 28px;
}

.btnAvatar {
  position: absolute;
  top: -53%;
  left: -25%;
  width: 65px;
}

.content_topic_desktop {
  background: url("../../../../assets/icon_svg/backgroundTopic.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  width: 30%;
  left: 31%;
  z-index: 1;
  flex-direction: column;
  align-items: center;
  top: 0%;

  @media screen and (min-width: 1024px) {
    top: 7%;
  }
}

.img_topic_desktop {
  bottom: 30%;
  left: 0%;
  display: block;
  width: 15%;
  position: absolute;
}

h1.content_title,
h1.content_title_lesson {
  font-size: 16px;
  color: #2d45d3;
  line-height: 25px;
}

.box_title {
  font-size: 13px;
  width: 80%;
  text-align: center;
  line-height: 8px;
  padding-bottom: 5px;
}

@media screen and (min-width: 1024px) {
  .content_writingCenter_BrainStorming {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .contents {
    width: 57%;
    height: 70vh;
  }

  .content_topic_desktop {
    top: 7%;
  }

  h1.content_title {
    color: white;
  }
  .box_title {
    color: white;
  }
}

@media screen and (min-width: 1280px) {
  .content_topic_desktop {
    top: 6%;
  }
}

@media screen and (min-width: 1440px) {
  .img_topic_desktop {
    width: 11%;
  }
  .content_feedbackEvaluation p {
    font-size: 16px;
    line-height: 1.5;
  }
  .box_title {
    font-size: 15px;
  }
}

@media screen and (min-width: 1920px) {
  .content_writingCenter {
    display: flex;
    flex-direction: row;
    width: 90%;
  }

  .group_btn {
    height: 40px;
    display: flex;
    justify-items: center;
    justify-content: center;
    gap: 60px;
    margin-left: 0px;
    margin-bottom: 20px;
  }

  .btnAvatar {
    position: absolute;
    top: -35%;
    left: -27%;
    width: 70px;
  }

  .button_writing_center {
    height: 45px;
    width: 160px;
    cursor: pointer;
    font-size: 20px;
  }

  .button_writing_center_nextSuggestion {
    height: 45px;
    width: 160px;
    cursor: pointer;
    font-size: 20px;
  }

  h1.content_title,
  h1.content_title_lesson {
    font-size: 28px;
    padding-top: 5px;
  }

  .box_title {
    font-size: 20px;
    width: 90%;
    text-align: center;
    line-height: 25px;
    padding-bottom: 2px;
  }

  .content_topic_desktop {
    top: 5%;
  }
}

.boxEbook {
  height: 50px;
}
