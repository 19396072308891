.content_col {
  flex-direction: column;
  align-items: center;
  position: relative;
  display: flex;
  justify-content: center;
  cursor: pointer;
  height: 100%;
}

.img {
  width: "70.3%";
  height: "65.4%";
}

.content_buttonHover {
  background: linear-gradient(
    93.04deg,
    #a2d8de -42.16%,
    #65bfcc 21.8%,
    #73d3d3 92.64%,
    #65bfcc 146.76%
  );
  color: white;
}

.contentTable {
  width: 90%;
}
.content_Menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contentTable h1 {
  font-size: 1rem;
}

.container_Home {
  width: 100%;
  background: url("../../../assets/background/backgound_home1.png") !important;
}

.contentHome {
  width: 100%;
  height: 90vh;
}

.boxAvt {
  width: 50%;
  height: 50%;
}

.contentHome_imgUser {
  width: 100%;
  height: 100%;
  border: 2px solid blue;
  border-radius: 100px;
}

.contentHome_inforUser,
.contentHome_inforUser_campus {
  width: 80%;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(9px);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 50px;
}

.contentHome_contentUser {
  width: 30%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentHome_contentHome {
  width: 70%;
  height: 85%;
  display: flex;
}

.itempage_dashboard {
  width: 100%;
}

.row_contentHome {
  height: 100%;
}
.row_contentCampus {
  display: flex;
  flex-direction: column;
}

.dashboadAdmin {
  justify-content: center;
}

td.ant-table-cell {
  padding: 10px 10px;
}

.row_information {
  height: 453px;
}

.Icon_left,
.Icon_right {
  position: absolute;
  width: 20%;
}
.Icon_left {
  left: 18%;
  top: 20%;
  transform: scaleX(-1);
}

.Icon_right {
  right: 18%;
  top: 20%;
}

.tableCourse {
}

@media screen and (min-width: 1024px) {
  .contentHome_inforUser,
  .contentHome_inforUser_campus {
    line-height: 40px;
  }
}

@media screen and (min-width: 1280px) {
  .contentHome_contentUser {
    width: 30%;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .contentHome_inforUser {
    width: 70%;
    margin-top: 32px;
  }
  .contentHome_inforUser_campus {
    width: 20%;
  }

  .img {
    width: 100%;
    height: 100%;
  }

  .contentHome_contentHome {
    width: 70%;
    height: 90%;
    padding-top: 10px;
  }
}

.content_Menu {
  position: relative;
  width: 65%;
  padding: 10px;
}

.content_Menu::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 30px;
  padding: 2px;
  background: #fe998d;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  opacity: 0;
  transition: opacity 0.3s;
}

.content_Menu:hover::before {
  opacity: 1;
}

.content_Menu_Campus {
  position: relative;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.content_Menu_Campus::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 30px;
  padding: 2px;
  background: #fe998d;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  opacity: 0;
  transition: opacity 0.3s;
}

.content_Menu_Campus:hover::before {
  opacity: 1;
}

.titlTalbe {
  font-weight: 400;
  font-size: 20px;
  color: #5ba3b9;
}

.nameTable {
  margin: unset;
}

.content_col .content_button {
  border: none;
  border-radius: 20px;
  color: white;
  background: #fe998d;
  width: fit-content;
  min-width: 145px;
  padding: 0 8px;
  height: 38px;
}

.content_button {
  color: white;
  background: #fe998d;
}

@media screen and (min-width: 1024px) {
  .titlTalbe {
    font-size: 50px;
    font-weight: 400;
  }
}

@media screen and (min-width: 1440px) {
  .contentHome_inforUser {
    width: 50%;
  }
  .content_Menu {
    width: 55%;
  }
}

@media screen and (min-width: 1920px) {
  .contentHome_contentUser {
    width: 30%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .contentHome_inforUser {
    width: 50%;
  }

  .img {
    width: 60%;
  }

  .contentHome_contentHome {
    width: 70%;
  }
}
