.popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 8px;
  position: absolute;
  width: 174px;
  height: 329px;
  background: #ffffff;
  box-shadow: 2px 0px 10px rgba(18, 18, 18, 0.1);
  border-radius: 8px;
  top: 100%;
  left: 20%;
}

.popup img {
  border: solid 2px blue;
  border-radius: 50px;
  padding: 1px;
}

.popup p {
  margin: unset;
}

.name {
  font-size: 0.8rem;
}

.title {
  font-style: normal;
  font-weight: 900;
  font-size: 25px;
  line-height: 60px;
  text-align: center;
  background: linear-gradient(139.36deg, #6b7aff -0.03%, #1430c6 120.49%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.level {
  -webkit-text-stroke: 1px #fe998d;
  color: white;
  font-style: normal;
  font-weight: 400;
  font-size: 45px;
  line-height: 26px;
  text-shadow: 0px 20px 32px rgba(101, 191, 204, 0.3);
}

.user_dashboard {
  color: #386386;
  margin-left: 8px;
  position: relative;
}

.box_avatar {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid blue;
  overflow: hidden;
  min-width: 30px;
  margin-left: 4px;
}

.img_user {
  width: 100%;
}

.row img {
  display: inline;
}

.divider {
  margin: 10px 0;
}

.user_logout,
.user_setting {
  width: 66%;
  display: grid;
  grid-template-columns: auto auto;
  gap: 80px;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.4);
}

.user_logout:hover,
.user_setting:hover {
  color: red;
}

.user_dashboard img {
  margin-right: 20px;
}

.btnSL:hover {
  color: red;
}

.textEmail {
  color: rgba(0, 0, 0, 0.4);
  line-height: 30px;
}

.nickName {
  background: linear-gradient(139.36deg, #6b7aff -0.03%, #1430c6 120.49%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

thead > tr > th {
  background-color: yellow;
}

.activeRow {
  background: rgba(101, 191, 204, 0.2);
}

@media only screen and (width <=1440px) {
  .user_dashboard {
    width: 100%;
    gap: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .title {
    font-size: 20px;
    line-height: 33px;
    font-weight: 700;
  }
  .level {
    font-size: 25px;
    line-height: 30px;
  }
  .name {
    font-size: 8px;
  }
}

@media only screen and (min-width: 1280px) {
  .title {
    font-style: normal;
    font-size: 25px;
    line-height: 45px;
  }
  .level {
    font-style: normal;
    font-weight: 400;
    font-size: 27px;
    line-height: 40px;
  }
  .name {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1440px) {
  .title {
    font-style: normal;
    font-size: 25px;
    line-height: 45px;
  }
}
